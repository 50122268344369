import React from 'react'
import { Button } from '@mui/material'
import "./DeleteButton.css"
function DeleteButton({children,onClick,style}) {
  return (
    <Button  style={style && style} className='removeButton'  onClick={onClick}>
      {children}
    </Button>
  )
}

export default DeleteButton