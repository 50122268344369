import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Typography, Slide, Divider, Box } from "@mui/material";

import Button from "@mui/material/Button";

// import FormControl from '@mui/material/FormControl';

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});

function LimitExceededModal({ open, handleClose,Duration,Storage }) {
  const handleCloseBox = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ textAlign: "center", backgroundColor: "#F5F7FB" }}>
          Limit Exceeded
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 8,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box pt={3}>
              <ReportProblemIcon color="error" sx={{ fontSize: 80 }} />
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <Typography variant="h5">
                {Duration && !Storage 
                  ? "Your session duration has exceeded the allowed limit. Please upgrade your plan or purchase an addon to continue."
                  : Storage && !Duration
                  ? "You have used up all your storage space. Please free up space, upgrade your storage plan, or purchase an addon."
                  : "Both duration and storage limits are exceeded.Upgrading to an add-on plan for additional benefits."}
              </Typography>
              {/* <Typography variant="subtitle6" gutterBottom>
              You already have a existing subscription
              </Typography> */}
            </Box>
          </Box>
          <div></div>
          <div style={{ marginTop: "20px" }}></div>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={handleCloseBox}
            sx={{
              border: "1px solid #444444",
              padding: "10px 20px",
              color: "#444444",
              "&:hover": {
                backgroundColor: "#F5F7FB",
              },
            }}
          >
            Cancel
          </Button> */}
          <Button
            onClick={handleCloseBox}
            sx={{
              //   border: "1px solid #0077c2",
              //   padding: "10px 20px",
              //   color: "#ffff",
              //   backgroundColor: "#1A73E8",
              //   "&:hover": {
              //     backgroundColor: "#0D5EBD",
              //   },

              border: "1px solid #B30000",
              padding: "10px 20px",
              color: "#ffff",
              backgroundColor: "#FF4D4D",
              "&:hover": {
                backgroundColor: "#CC0000",
              },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LimitExceededModal;
