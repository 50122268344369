import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
// utils
// import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
import AccountPopover from "./AccountPopover";
import { useNavigate } from "react-router-dom";
import Logo from "src/components/Logo";
// import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

// const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }),
  backgroundColor: "white",
  color: "black",
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    width: "100%",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const navigator = useNavigate()
  return (
    <StyledRoot>
      <StyledToolbar>
        <Box onClick={()=>navigator('/')}
          sx={{
            cursor:"pointer",
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
            },
          }}
        >
          {/* <Typography
            sx={{
              fontWeight: 600,
              fontSize: "48px",
              lineHeight: "28px",
              letterSpacing: "0.15%",
              color: "#1A73E8",
              display: {
                xs: "none",
                sm: "none",
                lg: "block",
              },
            }}
          >
            Farlanes
          </Typography> */}
          <Logo/>
          {/* <img src={Atlearn_Logo} alt="logo" /> */}
        </Box>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <LanguagePopover />
          <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
