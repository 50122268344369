import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import "./SingleRoom.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import HomeIcon from "@mui/icons-material/Home";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Recordings from "src/components/Recordings/Recordings";
import Presentation from "src/components/Presentation/Presentation";
import Access from "src/components/Acces/Access";
import Settings from "src/components/Settings/Settings";
import MainButton from "src/components/Button/MainButton/MainButton";
import SecondaryButton from "src/components/Button/SecondaryButton/SecondaryButton";
import apiClients from "src/apiClients/apiClients";
import { toast } from "react-toastify";
// import ShareIcon from "@mui/icons-material/Share";
import SheduleRoom from "./ScheduleRoom";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useSelector } from "react-redux";
import RoomHistory from "src/components/RoomHistory/RoomHistory";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import { checkDuration, checkStorage } from "src/utils/addonCheck/addonUtils";
import Notification from "src/components/Notification/expiredNotification";
function SingleRoom() {
  const { user } = useSelector((state) => state.user);
  const [value, setValue] = React.useState("1");
  const navigate = useNavigate();
  const friendly_id = useParams();
  const [room, setRoom] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [sheduleOpen, setScheduleOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [shareRoom, setShareRoom] = useState(false);
  // const [presentation, setPresentation] = useState(false);
  const [addonDuration, setAddonDuration] = useState(false);
  const [addonStorage, setAddonStorage] = useState(false);
  const [expired, setExpired] = useState(false);
  const openmenu = Boolean(anchorEl);
  const url = `${window.location}/join`;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const settings = queryParams.get("settings");

  useEffect(() => {
    if (settings === "true") {
      setValue("4");
    }
  }, [settings]);
  useEffect(() => {
    siteSetting();
    if (friendly_id) {
      fetchData(friendly_id.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendly_id]);

  useEffect(() => {
    if (user?.user) {
      const usedStorageKB = 10485750;
      const totalStorageGB = 10;
      const usedSeconds = user?.user?.duration_spent;
      const totalHours =
        user?.user?.subscription?.duration + user?.user?.addon_duration;

      // Use utility functions
      setAddonStorage(checkStorage(usedStorageKB, totalStorageGB));
      setAddonDuration(checkDuration(usedSeconds, totalHours));
    }
  }, [user]);

  const handleScheduleOpen = () => {
    handleMenuClose();
    setScheduleOpen(true);
  };
  const handleScheduleClose = () => {
    setScheduleOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    handleMenuClose();
    setOpen(true);
  };

  const handleClose = () => {
    setEmail("");
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async (id) => {
    try {
      const response = await apiClients.getOneRoom(id);
      if (response.data) {
        setRoom(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStartMeeting = async (id) => {
    try {
      setLoading(true);
      const response = await apiClients.startMeeting(id);
      if (response.data) {
        window.location.href = response.data.joinModeratorUrl;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`${window.location}/join`);
    toast.success(
      "The meeting URL has been copied. The link can be used to join the meeting."
    );
  };

  const handleShareRoom = async () => {
    try {
      if (!Boolean(emailError)) {
        const data = {
          email: email,
          url: url,
          room: room?.name,
        };
        // eslint-disable-next-line no-unused-vars
        const response = apiClients.shareRoom(data);
        toast.success("The room has been successfully shared via email");
        setEmail("");
        setOpen(false);
      } else {
        setEmailError("Invalid email address");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = (input) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    if (validateEmail(inputValue)) {
      setEmailError("");
    } else {
      setEmailError("Invalid email address");
    }
  };

  const siteSetting = async () => {
    const data = {
      name: ["ShareRooms", "PreuploadPresentation"],
    };
    try {
      const response = await apiClients.getSiteSettings(data);
      if (response.data) {
        response.data.forEach((item) => {
          switch (item.setting.name) {
            case "ShareRooms":
              // setShareRoom(item.value);
              setShareRoom(item.value === "true" ? true : false);
              // setShareId(item.id);
              break;
            // case "PreuploadPresentation":

            //   setPresentation(item.value === "true" ? true : false);

            //   break;
            default:
              break;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <Notification setNotfication={setExpired} type={"modal"} />
        <Container maxWidth={"xl"}>
          <div onClick={() => navigate("/room")} style={{ cursor: "pointer" }}>
            <Box sx={{ pt: 12 }}>
              <IconButton>
                <ArrowCircleLeftRoundedIcon
                  fontSize="large"
                  sx={{ color: "#E8063C" }}
                />
              </IconButton>
            </Box>
          </div>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <Box>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h2"
                      gutterBottom
                      // pt={5}
                      className="mainHead"
                    >
                      {room?.name}
                    </Typography>
                    <Box sx={{ display: { xs: "block", sm: "none" } }}>
                      <IconButton onClick={handleClick} aria-label="Example">
                        <MoreVertOutlinedIcon />
                      </IconButton>
                      {sheduleOpen && (
                        <SheduleRoom
                          open={sheduleOpen}
                          handleClosebox={handleScheduleClose}
                          url={url}
                          room={room}
                        />
                      )}
                    </Box>
                  </div>
                  {room?.last_session ? (
                    <div>
                      <Typography variant="subtitle1">
                        Last Session: {room?.last_session}
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      <Typography variant="subtitle1">
                        No previous session created
                      </Typography>
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid className="grid_main" item xs={12} lg={4}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    // alignItems: "center",
                    gap: 2,
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <SecondaryButton
                    // onClick={() => navigate(`/join/${room?.friendly_id}`)}
                    onClick={handleCopy}
                  >
                    <ContentCopyIcon sx={{ marginRight: "10px" }} />
                    Copy Join Link
                  </SecondaryButton>
                  <Tooltip
                    title={
                      addonDuration
                        ? "Duration limits exceeded.Upgrading to an add-on plan."
                        : ""
                    }
                  >
                    <Box sx={{ xs: { width: "100%" } }}>
                      <MainButton
                        style={{ width: "100%" }}
                        disabled={addonDuration || expired}
                        onClick={() => handleStartMeeting(room.friendly_id)}
                      >
                        {loading && (
                          <CircularProgress
                            size={"1.2rem"}
                            sx={{ color: "white" }}
                          />
                        )}
                        <Box ml={loading ? 2 : 0}>
                          {room?.online ? "Join Meeting" : "Start Meeting"}
                        </Box>
                      </MainButton>
                    </Box>
                  </Tooltip>

                  {open && (
                    <Box>
                      <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Share Room</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            To invite others to join this room, please enter
                            their email addresses here.
                          </DialogContentText>

                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            value={email}
                            onChange={handleEmailChange}
                            error={Boolean(emailError)}
                            helperText={emailError}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Cancel</Button>
                          <Button onClick={handleShareRoom}>Share</Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  )}

                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <IconButton
                      // onClick={handleClickOpen}
                      onClick={handleClick}
                      aria-label="Example"
                    >
                      {/* <ShareIcon /> */}
                      <MoreVertOutlinedIcon />
                    </IconButton>
                    {sheduleOpen && (
                      <SheduleRoom
                        open={sheduleOpen}
                        handleClosebox={handleScheduleClose}
                        url={url}
                        room={room}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openmenu}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClickOpen}>Share Room</MenuItem>
            <MenuItem onClick={handleScheduleOpen}>Schedule Meeting</MenuItem>
          </Menu>

          <Box sx={{ marginTop: "50px" }}>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box>
                  <TabList
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                  >
                    <Tab className="tabheading" label="Recordings" value="1" />
                    {/* {presentation && (
                        <Tab
                          className="tabheading"
                          label="Presentation"
                          value="2"
                        />
                      )} */}

                    {user?.user?.subscription?.sharedRoomAccess === "true" &&
                      shareRoom && (
                        <Tab className="tabheading" label="Access" value="3" />
                      )}

                    <Tab className="tabheading" label="Settings" value="4" />
                    <Tab
                      className="tabheading"
                      label="Room History"
                      value="5"
                    />
                  </TabList>
                </Box>
                <Box
                  sx={{
                    marginBottom: "20px",
                    background: "rgb(255, 255, 255)",
                    minHeight: "30vh",
                    borderRadius: "12px",
                    boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.5)",
                    // boxShadow: " 2px 0px 5px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.2s",
                  }}
                >
                  <TabPanel value="1">
                    <Recordings friendly_id={room?.friendly_id} />
                  </TabPanel>
                  <TabPanel value="2">
                    <Presentation />
                  </TabPanel>
                  <TabPanel value="3">
                    <Access Roomid={room?.id} />
                  </TabPanel>
                  <TabPanel value="4">
                    <Settings
                      getdata={fetchData}
                      Roomid={room?.id}
                      Duration={addonDuration}
                      Storage={addonStorage}
                    />
                  </TabPanel>
                  <TabPanel value="5">
                    <RoomHistory meeting_id={room?.meeting_id} />
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>
          </Box>
        </Container>
      </div>
    </>
  );
}

export default SingleRoom;
