import Accordion from "../../components/Accordion";
import Features from "../../components/Features";
import Header from "../../components/Header";
import HeaderText from "../../components/HeaderText";
import Paragraph from "../../components/Paragraph";
import Button from "../../components/Button";
import backgroundcta from "../../assets/img/backgroundd-cta1.webp";
import InfoSection from "src/components/InfoSection";
import Footer from "src/components/Footer";
import InfoCard from "src/components/InfoCard";
import { useNavigate } from "react-router-dom";
import {
  courseManagementFeatures,
  features,
  AssessmentAndQuiz,
  EfficientStudent,
  FlexibleTime,
  AtLearnExperience,
  AtlearnBook,
  CourseProgress,
  CourseProgressInfo,
  CoursemanagementInfo,
} from "src/Data/studentPageData";
const Students = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Header
        // backgroundImage={"/cover_images/ExperiencetheBestofPersonalized.png"}
        backgroundImage={"/cover_images/students.svg"}
        pageTitle={"Students"}
        subTitle={"Empowering students to achieve their dreams with personalized learning tools"}
        cta={"Learn now"}
      />
      <div className="mt-8">
        <InfoSection info={CourseProgressInfo} />
        <InfoCard cardData={CourseProgress} />
      </div>

      <div>
        <InfoSection info={AssessmentAndQuiz} imageSide />
      </div>

      <div>
        <InfoSection info={EfficientStudent} rounded />
      </div>

      <div className="">
        <section className="text-neutral-700 pb-16 relative" id="section-1">
          <InfoSection info={CoursemanagementInfo} />
          <div >
            <Accordion items={courseManagementFeatures} />
          </div>
        </section>
      </div>
      <div>
        <InfoSection info={FlexibleTime} />
      </div>

      <div>
        <InfoSection info={AtLearnExperience} />
      </div>
      <div>
        <Features features={features} />
      </div>

      <div className="pt-8">
        <InfoSection info={AtlearnBook} />
      </div>

      <section
        className=" text-neutral-700 bg-no-repeat bg-cover py-28"
        id="section-1"
        style={{
          backgroundPosition: "50% 50%",
          backgroundImage: `url(${backgroundcta})`,
        }}
      >
        <div className="flex m-auto">
          <div className="flex min-h-[0.06rem] md:w-full" id="div-1">
            <div className="content-start flex-wrap flex w-full">
              <section className="w-full">
                <div className="flex max-w-[82.50rem] m-auto" id="div-2">
                  <div className="flex min-h-[0.06rem] md:w-full" id="div-3">
                    <div className="content-start flex-wrap px-4 flex w-full">
                      <div
                        className="flex-col justify-center w-full"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div>
                          <div
                            className="bg-fuchsia-950 md:rounded-bl-full md:rounded-br-full md:rounded-tl-full md:rounded-tr-3xl rounded-xl sm:p-20 p-10 max-w-[38.13rem]"
                            id="div-4"
                          >
                            <h2 className="text-white text-[30px] leading-none font-semibold -mt-1 mb-5 font-fredoka">
                              Simplify Your Educational Material Management
                            </h2>

                            <p className="text-zinc-300 text-lg mb-8 font-fredoka">
                              Manage and distribute educational materials
                              effortlessly with Atlearn&apos;s streamlined book
                              featur
                            </p>

                            <Button
                              onClick={() => navigate("/signup")}
                              text={"Start Registration"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Students;
