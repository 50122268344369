import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { userSlice } from "./userSlice";
import addonPlanReducer from "./addonplanSlice";
const rootReducer = combineReducers({
  user: userSlice.reducer,
  addonPlan: addonPlanReducer,
});

const store = configureStore({
  reducer: rootReducer,
});
export default store;
