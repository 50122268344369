import React, { useState, useEffect } from "react";
import "./EditUser.css";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import apiClients from "src/apiClients/apiClients";
import { setUser } from "src/Redux/userSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});

function EditUser({ open, handleclose, user, update, userID }) {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [roles, setRoles] = useState();
  const [role_id, setRole_Id] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState(" ");
  const [language, setLanguage] = useState("");
  const [userId, setUserId] = useState("");
  const [profile, setProfile] = useState("");
  const [checkName, setCheckName] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [checkRole, setCheckRole] = useState(false);
  const [checkLanguage, setCheckLanguage] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchRoles();
    if (user) {
      setName(user?.name);
      setEmail(user?.email);
      setRole_Id(user?.role.id);
      setLanguage(user?.language);
      setSelectedRole(user?.role);
      setUserId(user?.id);
      setProfile(user?.avatar ? user?.avatar : "");
      // console.log(user,"user?.user?.avatar");
    }
  }, [user]);

  const handleClosebox = () => {
    // setOpenuser(false);
    setCheckName(false);
    setCheckEmail(false);
    setCheckRole(false);
    setCheckLanguage(false);
    handleclose();
  };
  const fetchRoles = async () => {
    try {
      const response = await apiClients.getAllRoles();
      if (response.data) {
        // setFilteredRole(response.data);
        setRoles(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const isEmailValid = (email) => {
    // const emailRegex = /^[a-z][a-z0-9._-]*@[a-z0-9.-]+\.[a-z]{2,}$/;
    const emailRegex = /^[a-z][a-z0-9._-]*@[a-z0-9-]+(\.[a-z]{2,}){1}$/;
    return emailRegex.test(email);
  };

  const checkAllField = () => {
    let error;
    if (!name) {
      setCheckName(true);
      error = true;
    }

    if (email) {
      const isValid = isEmailValid(email);
      if (!isValid) {
        setCheckEmail(!isValid);
        setEmailError("Invalid Email");
        error = true;
      }
    } else {
      setCheckEmail(true);
      setEmailError("Email is required");
      error = true;
    }

    if (!selectedRole) {
      setCheckRole(true);
      error = true;
    }

    if (!language) {
      setCheckLanguage(true);
      error = true;
    }

    if (error) {
      return error;
    } else {
      return false;
    }
  };
  const handleUpdateUser = async () => {
    try {
      const checkRequiredField = checkAllField();
      if (checkRequiredField === false) {
        const data = {
          name,
          email,
          role_id,
          language,
        };
        const response = await apiClients.userUpdate(data, userId);
        if (response.data) {
          update();
          handleclose();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteProfile = async () => {
    try {
      const response = await apiClients.deleteProfile(userId);
      if (response.success === true) {
        setSelectedFile(null);
        setProfile("");
        toast.success("avatar has been updated");
        if (userID === userId) {
          session();
        }
        update();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const session = async () => {
    try {
      const responseData = await apiClients.sessionData();
      if (responseData?.success === true) {
        if (responseData?.data) {
          dispatch(setUser(responseData.data));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setEditUser((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));

  //   console.log();
  // };

  const uploadProfile = async (selectedFile) => {
    try {
      const formData = new FormData();
      formData.append("image", selectedFile);
      setLoading(true)
      const response = await apiClients.uploadProfile(userId, formData);
      if (response) {
        if (userID === userId) {
          session();
        }
        update();
        toast.success("avatar has been updated");
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  const isFileTypeValid = (file) => {
    const validTypes = ["image/png", "image/jpeg", "image/svg+xml"];
    return validTypes.includes(file.type);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && isFileTypeValid(file)) {
      setSelectedFile(file);
      // setFileError("");
      uploadProfile(file);
    } else {
      setSelectedFile(null);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosebox}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textAlign: "center", backgroundColor: "#F5F7FB" }}>
          Edit User
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ textAlign: "center", marginBottom: "1rem" }}>
            <Box {...getRootProps()}>
              <input {...getInputProps()} />
              {selectedFile ? (
                <Avatar
                  src={URL.createObjectURL(selectedFile)}
                  // alt="Profile Picture"
                  sx={{
                    width: 120,
                    height: 120,
                    margin: "0 auto",
                    border: "4px solid #fff",
                    cursor: "pointer",
                    // backgroundColor: "#2b558f",
                  }}
                />
              ) : (
                <Avatar
                  src={`${process.env.REACT_APP_OVERRIDE_HOST}/api/images/${profile}`}
                  // alt="Profile Picture"
                  sx={{
                    width: 120,
                    height: 120,
                    margin: "0 auto",
                    border: "4px solid #fff",
                    cursor: "pointer",
                    // backgroundColor: "#2b558f",
                  }}
                />
              )}
            </Box>
            <Box
              mt={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Button onClick={deleteProfile} className="delete">
                Delete
              </Button>
              <Box {...getRootProps()}>
                <input {...getInputProps()} />
                <Button                     
                      sx={{
                      border: "1px solid #6D207B",
                      padding: "10px 20px",
                      color: "#ffff",
                      backgroundColor: "#6D207B",
                      "&:hover": {
                        backgroundColor: "#E8063C",
                      },
                    }}>
                  {" "}
                  {loading ? (
                    <CircularProgress
                      size={"1.2rem"}
                      sx={{ color: "white", mr: 1, backgroundColor: "#6D207B",
                      "&:hover": {
                        backgroundColor: "#E8063C",
                      },}}
                    />
                  ) : (
                    <FileUploadOutlinedIcon sx={{ mr: 1 }} />
                  )}
                  Upload
                </Button>
              </Box>
              <Box></Box>
            </Box>
          </Box>
          <Box sx={{ mt: 6 }}>
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Name *</Typography>
                  <TextField
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setCheckName(false);
                    }}
                    fullWidth
                    error={checkName}
                    helperText={checkName ? "Name is required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Email *</Typography>
                  <TextField
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setCheckEmail(false);
                      setEmailError("");
                    }}
                    fullWidth
                    error={checkEmail}
                    helperText={emailError}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Role *</Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.name}
                    options={roles ? roles : []}
                    value={selectedRole}
                    onChange={(event, newValue) => {
                      setCheckRole(false);
                      setRole_Id(newValue?.id ? newValue.id : "");
                      setSelectedRole(newValue ? newValue : null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="role"
                        error={checkRole}
                        helperText={checkRole ? "Role is required" : ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Language *</Typography>
                  <TextField
                    type="text"
                    placeholder="Language "
                    value={language}
                    onChange={(e) => {
                      setLanguage(e.target.value);
                      setCheckLanguage(false);
                    }}
                    fullWidth
                    error={checkLanguage}
                    helperText={checkLanguage ? "Language is required" : ""}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mb: 2,
                    gap: 1,
                  }}
                >
                  <Button
                    onClick={handleClosebox}
                    sx={{
                      border: "1px solid #444444",
                      padding: "10px 20px",
                      color: "#444444",
                      "&:hover": {
                        backgroundColor: "#F5F7FB",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleUpdateUser}
                    sx={{
                      border: "1px solid #6D207B",
                      padding: "10px 20px",
                      color: "#ffff",
                      backgroundColor: "#6D207B",
                      "&:hover": {
                        backgroundColor: "#E8063C",
                      },
                    }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditUser;
