import React from 'react'

const RoleIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_323_4521)">
                <path d="M12 12C14.0711 12 15.75 10.3211 15.75 8.25C15.75 6.17893 14.0711 4.5 12 4.5C9.92893 4.5 8.25 6.17893 8.25 8.25C8.25 10.3211 9.92893 12 12 12Z" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.4416 19.5C18.4416 16.5975 15.5541 14.25 11.9991 14.25C8.44414 14.25 5.55664 16.5975 5.55664 19.5" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18 12.2792V11.7208C18 11.3906 18.2552 11.1204 18.5671 11.1204C19.1115 11.1204 19.3327 10.7121 19.0605 10.2137C18.9017 9.92554 18.9981 9.55328 19.2703 9.39117L19.7864 9.07295C20.0246 8.92284 20.3308 9.0129 20.4726 9.26508L20.5066 9.32512C20.7788 9.82347 21.2212 9.82347 21.4934 9.32512L21.5274 9.26508C21.6692 9.0129 21.9754 8.92885 22.2136 9.07295L22.7297 9.39117C23.0019 9.55929 23.0983 9.92554 22.9395 10.2137C22.6673 10.7121 22.8885 11.1204 23.4329 11.1204C23.7448 11.1204 24 11.3906 24 11.7208V12.2792C24 12.6094 23.7448 12.8796 23.4329 12.8796C22.8885 12.8796 22.6673 13.2879 22.9395 13.7863C23.0983 14.0745 23.0019 14.4467 22.7297 14.6088L22.2136 14.9271C21.9754 15.0772 21.6692 14.9871 21.5274 14.7349L21.4934 14.6749C21.2212 14.1765 20.7788 14.1765 20.5066 14.6749L20.4726 14.7349C20.3308 14.9871 20.0246 15.0712 19.7864 14.9271L19.2703 14.6088C18.9981 14.4407 18.9017 14.0745 19.0605 13.7863C19.3327 13.2879 19.1115 12.8796 18.5671 12.8796C18.2552 12.8856 18 12.6154 18 12.2792Z" stroke="#6D207B" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21 13C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11C20.4477 11 20 11.4477 20 12C20 12.5523 20.4477 13 21 13Z" stroke="#6D207B" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_323_4521">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default RoleIcon
