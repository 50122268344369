import meetingApp from "src/assets/images/online-classes/onlinegiftools1.gif";
import learning from "src/assets/images/online-classes/onlinegiftools0.gif";
import createroom from "src/assets/images/online-classes/createroom.png"
import joinroom from "src/assets/images/online-classes/joinroom.png"
import connected from "src/assets/images/online-classes/connected.png"





 export const experienceContent = {
    title: "Enhance Your Video Meetings ",
    description:
      "Elevate your virtual experience as we redefine the standards for premium interactions, providing you with a seamless and enriched journey in every meeting. Join us in shaping a new era of excellence through our meticulously crafted, high-quality virtual gatherings.",
    image: meetingApp,
  };

  export const supportConetent = {
    title: "Experience Stunning Video Quality ",
    description: "",
    points: [
      "Streamline communication, foster connections, and promote more effective collaboration in boardrooms, classrooms, operating rooms, and all other environments.",
      "Seize control of your meetings with powerful host and participant controls, flexible recording options, and personalized entry policies.",
      "Leverage chat features, sharedNotes, breakout rooms, and unlock the ability to record sessions for a comprehensive and interactive experience",
    ],
    image: learning,
  };


  export const featuresContent = [
    {
      icon: joinroom,
      title: "Join Room",
      description:
        "Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.",
    },
    {
      icon:createroom,
      title: "Create Room",
      description:
        "Craft dynamic remote and hybrid learning ecosystems, empower educators and learners, and foster greater equity in educational access.",
    },
    {
      icon: connected,
      title: "Stay Connected",
      description:
        "Unify your teams and optimize communication flows with seamless instant messaging integrated within the atlearn ecosystem.",
    },
  ];