import React from "react";
import Header from "../../components/Header";
import Footer from "src/components/Footer";
import { termsSections } from "src/Data/termsConditionsPageData";
function TermsConditions() {
  return (
    <>
      <Header
        backgroundImage={"/cover_images/TermsandConditions.svg"}
        pageTitle={"Terms and Conditions"}
      />

      <div className="max-w-7xl mx-auto mt-10 p-4">
        <div
          className="flex-col justify-center w-full mb-5 text-6xl text-black font-semibold capitalize"
          style={{
            alignItems: "initial",
          }}
        >
          {/* <div className="-mb-5">
          <div className="mb-[calc(60px_-_22px)]" id="div-22">
            <h3 className="-mt-1 mb-3">Terms and Conditions for Atlearn</h3>
          </div>
        </div> */}
        </div>

        <div className="bg-white shadow-md rounded-lg p-6 max-w-8xl w-full">
          <div className="space-y-4">
            {termsSections.map((section, index) => (
              <div key={index} className="mb-4">
                <h2 className="text-2xl font-semibold">{section.title}</h2>
                {Array.isArray(section.content) ? (
                  <ul className="list-disc list-inside ml-5">
                    {section.content.map((item, subIndex) => (
                      <li key={subIndex} className="mt-2">
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="mt-2">{section.content}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsConditions;
