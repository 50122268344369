import onlineLearnImg from "src/assets/img/online_learning_solutions.webp";
import liveconferencesImg from "src/assets/images/about/live_conferences1.webp";

export const onlineLearningSolutions = {
  title: "Comprehensive Online Learning Solutions",
  description:
    "In the realm of online education, our company offers a wide array of services tailored to meet the diverse needs of our clients",
  points: [
    "Seamless virtual classroom experience",
    "Easy connectivity from anywhere",
    "Enhanced collaboration and engagement",
    "User-friendly interface for effortless navigation",
    "Interactive tools for dynamic learning experiences",
  ],
  image: onlineLearnImg,
};

export const liveConferences = {
  title: "Live Conferences And Meetings",
  description:
    "Live Conferences and Meetings: Our platform facilitates real-time interaction and collaboration with customizable features, ensuring a secure environment.",
  points: [
    "Real-time interaction facilitation",
    "Robust online meeting platform",
    "Fostered collaboration and engagement",
    "Customizable features for personalized experiences",
  ],
  image: liveconferencesImg,
};

export const testimonials = [
  {
    text: "Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text. It's not Latin, though it looks like it, and it actually says nor is",
    name: "Jannie Marko",
  },
  {
    text: "From its medieval origins to the digital era, learn everything there is to know about the ubiquitous lorem ipsum passage sometimes known, is dummy. ",
    name: "Vivi Marian",
  },
];
