import React, { useEffect, useState } from "react";
// import { CgSearch } from 'react-icons/cg';
import {
  FaBars,
  FaChevronDown,
  FaChevronRight,
  FaEnvelope,
  FaMinus,
  FaMobileAlt,
  FaPlus,
  // FaRegCalendarAlt,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Logo from "src/components/Logo";
import AccountPopover from "../dashboard/header/AccountPopover";
import { LiaTimesSolid } from "react-icons/lia";
import { PopupModal } from "react-calendly";
import courseManagement1 from "../../assets/images/Blogs/Course-Management.png";
import courseManagement2 from "../../assets/images/Blogs/Course-Management-2.png";
import courseManagement3 from "../../assets/images/Blogs/Course-Management-1.png";
// import ExamBlogImg from "../../assets/images/Blogs/Exam-Blog-post-indian.png";
// import BlogPost from "../../assets/images/Blogs/Blog-post-indian.png";
// import FriendPost from "../../assets/images/Blogs/Friends-Blog-post-indian.png";
const APIURL = window.location.origin;
function LayoutMain() {
  // const redirectToApi = () => {
  //   window.open(`${APIURL}/api/docs/`, "_blank");
  // };
  const navItems = [
    {
      title: "Home",
      link: "/",
      children: [],
    },
    {
      title: "LMS",
      link: "/lms",
      children: [
        { title: "Teachers", link: "/teachers" },
        { title: "Students", link: "/students" },
        { title: "School", link: "/school" },
      ],
    },
    {
      title: "Online Classes",
      link: "/online-classes",
      children: [
        { title: "Features", link: "/features" },
        // { title: "Pricing", link: "/pricing" },
        { title: "API", link: `${APIURL}/api/docs/` },
      ],
    },
    {
      title: "About",
      link: "/about",
      children: [],
    },
    {
      title: "Pricing",
      link: "/pricing",
      children: [],
    },
    {
      title: "Contact",
      link: "/contact",
      children: [],
    },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [openSections, setOpenSections] = useState({});
  const { user } = useSelector((state) => state.user);
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const [showSideBar, setShowSideBar] = useState(false);
  const [showLeftSideBar, setShowLeftSideBar] = useState(false);
  useEffect(() => {
    if (user?.user) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [user]);

  const toggleSection = (title) => {
    setOpenSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };
  return (
    <div>
      <PopupModal
        url="https://calendly.com/atlearn/30min"
        // url="https://calendly.com/contact-atlearn/30min"

        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
      <div className="py-3 px-28 bg-primary text-white flex justify-center lg:justify-between items-center ">
        <div className="gap-4 items-center hidden lg:flex">
          {/* <CgSearch size={25} color="white" />
          <a href="/#" className="font-jost">
            Search Keyword
          </a> */}
        </div>

        <div className="flex gap-4 md:gap-8 flex-wrap  justify-center">
          <div className="flex gap-4 items-center group">
            <Button onClick={() => setIsOpen(true)}>Request Demo</Button>
          </div>
          <div className="flex gap-4 items-center group">
            <div className="w-10 h-10 bg-transparent group-hover:bg-secondary group-hover:ring-secondary duration-300 rounded-full ring-1 ring-white flex justify-center items-center">
              <FaEnvelope size={17} color="white" />
            </div>

            <a
              href="mailto:contact@atlearn.in"
              className="hover:text-secondary duration-300 font-jost"
            >
              support@atlearn.in
            </a>
          </div>

          <div className="flex gap-4 items-center group">
            <div className="w-10 h-10 bg-transparent group-hover:bg-secondary group-hover:ring-secondary duration-300 rounded-full ring-1 ring-white flex justify-center items-center">
              <FaMobileAlt size={17} color="white" />
            </div>

            <a
              href="tel:8015801639"
              className="hover:text-secondary duration-300 font-jost"
            >
              +91 8015801639
            </a>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "white" }}
        className="md:px-28 px-4  justify-between items-center flex"
      >
        <Logo />

        <div className="lg:flex justify-between items-center hidden ">
          <div className="flex gap-10">
            <Link
              to={"/"}
              className="hover:text-secondary font-semibold text-lg	font-fredoka py-[38px]"
            >
              Home
            </Link>
            <div className="relative group">
              <Link to={"/lms"}>
                <button className="hover:text-secondary focus:outline-none font-semibold text-lg font-fredoka py-[38px] flex items-center gap-1">
                  LMS <FaChevronDown size={13} />
                </button>
              </Link>

              <div className="absolute left-0 mt-2 py-2 w-48 font-fredoka font-semibold bg-white rounded-md shadow-xl z-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <Link
                  to={"/students"}
                  className=" px-4 py-2 hover:bg-gray-200 flex items-center"
                >
                  <span className="mr-2 text-secondary">&#x25CF;</span> Students
                </Link>

                <Link
                  to={"/school"}
                  className=" px-4 py-2 hover:bg-gray-200 flex items-center"
                >
                  <span className="mr-2 text-secondary">&#x25CF;</span> School
                </Link>
                <Link
                  to={"/teachers"}
                  className=" px-4 py-2 hover:bg-gray-200 flex items-center"
                >
                  <span className="mr-2 text-secondary">&#x25CF;</span> Teachers
                </Link>
              </div>
            </div>
            <div className="relative group">
              <Link to={"/online-classes"}>
                <button className=" hover:text-secondary focus:outline-none font-semibold text-lg	font-fredoka py-[38px] flex items-center gap-1">
                  Online Classes <FaChevronDown size={13} />
                </button>
              </Link>

              <div className="absolute left-0 mt-2 py-2 w-48 font-fredoka font-semibold bg-white rounded-md shadow-xl z-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <Link
                  to={"/features"}
                  className=" px-4 py-2 hover:bg-gray-200 flex items-center"
                >
                  <span className="mr-2 text-secondary">&#x25CF;</span> Features
                </Link>
                {/* <Link
                  to={"/pricing"}
                  className=" px-4 py-2 hover:bg-gray-200 flex items-center"
                >
                  <span className="mr-2 text-secondary">&#x25CF;</span> Pricing
                </Link> */}
                <a
                  href={`${APIURL}/api/docs/`}
                  className=" px-4 py-2 hover:bg-gray-200 flex items-center"
                >
                  <span className="mr-2 text-secondary">&#x25CF;</span> Api
                </a>
              </div>
            </div>
            <Link
              to={"/about"}
              className="hover:text-secondary font-semibold text-lg	font-fredoka py-[38px]"
            >
              About
            </Link>
            <Link
              to={"/pricing"}
              className="hover:text-secondary font-semibold text-lg	font-fredoka py-[38px]"
            >
              Pricing
            </Link>
            <Link
              to={"/contact"}
              className="hover:text-secondary font-semibold text-lg	font-fredoka py-[38px]"
            >
              Contact
            </Link>
          </div>
        </div>

        <div className="flex items-center gap-4 ml-24">
          <FaBars
            size={25}
            className="cursor-pointer hidden lg:block"
            onClick={() => {
              setShowSideBar(true);
            }}
          />

          <div
            className="bg-secondary lg:hidden w-12 h-12 rounded-full flex justify-center items-center"
            onClick={() => {
              setShowLeftSideBar(true);
            }}
          >
            <FaBars size={25} color="white" className="cursor-pointer" />
          </div>
          <div className="hidden lg:block ">
            {active ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
              >
                <AccountPopover />
              </div>
            ) : (
              <Button text={"Login"} onClick={() => navigate("/login")} />
            )}
          </div>
        </div>
      </div>
      {showSideBar ? (
        <div
          className={`bg-black bg-opacity-80 w-full h-screen absolute top-0 right-0 z-50 ease-in-out duration-700 `}
        >
          <div className="bg-white min:w-96 w-1/4 h-screen float-right p-5 overflow-x-auto no-scrollbar">
            <div className="bg-primary rounded-full w-12 h-12 float-right flex justify-center items-center">
              <LiaTimesSolid
                color="white"
                size={25}
                className="cursor-pointer"
                onClick={() => {
                  setShowSideBar(false);
                }}
              />
            </div>
            <h2 className="text-[36px] font-fredoka font-semibold leading-none">
              Giving your child the best start in life
            </h2>

            {/* <div className="flex gap-4 mt-4">
              <img src="/src/assets/icons/location.svg" alt="" />
              <span className="text-[18px] font-jost">
                Plot 88B, 2nd Avenue, Vettuvenkkeni, Chennai 600115, India
              </span>
            </div> */}

            <div className="flex w-full gap-2 mt-4">
              <img
                className="rounded-2xl w-36 h-36"
                src={courseManagement1}
                alt=""
              />
              <img
                className="rounded-2xl w-36 h-36"
                src={courseManagement2}
                alt=""
              />
              <img
                className="rounded-2xl w-36 h-36"
                src={courseManagement3}
                alt=""
              />
            </div>

            <h2 className="text-[30px] font-fredoka font-semibold leading-none mt-5">
              Get In Touch
            </h2>
            <div className="w-8 h-1 rounded-full bg-yellow-300 mt-1" />
            <div
              className="flex-col justify-center w-full max-w-full mb-5 text-lg mt-5 mb-2  "
              style={{
                alignItems: "initial",
              }}
            >
              <div className="flex items-center font-jost">
                <div className="bg-yellow-400 font-light h-11 leading-10 mr-3 text-center align-middle w-11 rounded-full flex justify-center items-center">
                  <FaEnvelope color="black" fill="black" />
                </div>
                contact@atlearn.in
              </div>
            </div>

            <div
              className="flex-col justify-center w-full max-w-full text-lg"
              style={{
                alignItems: "initial",
              }}
            >
              <div className="flex items-center font-jost" id="li-3">
                <div className="bg-yellow-400 font-black h-11 leading-10 mr-3 text-center align-middle w-11 rounded-full flex justify-center items-center">
                  <FaMobileAlt fill="black" />
                </div>
                +91 8015801639
              </div>
            </div>

            <div
              className="flex-col justify-center w-full max-w-full mb-5 text-lg mt-2  "
              style={{
                alignItems: "initial",
              }}
            >
              <div className="flex  items-center font-jost">
                <div className="bg-yellow-400 font-light h-11 leading-10 mr-3 text-center align-middle w-11 rounded-full flex justify-center items-center">
                  <FaLocationDot color="black" fill="black" />
                </div>
                <span className="w-72">
                  Plot 88B, 2nd Avenue, Vettuvenkkeni, Chennai 600115, India
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {showLeftSideBar ? (
        <div
          className={`bg-black bg-opacity-80 w-full h-screen absolute top-0 left-0 z-50 ease-in-out duration-700 `}
        >
          <div className="bg-white w-96 min-w-96 h-screen p-5 overflow-x-auto no-scrollbar">
            <div className="bg-secondary rounded-full w-12 h-12 float-right flex justify-center items-center">
              <LiaTimesSolid
                color="white"
                size={25}
                className="cursor-pointer"
                onClick={() => {
                  setShowLeftSideBar(false);
                }}
              />
            </div>
            <Logo />

            <ul className="space-y-4 mt-10">
              {navItems.map((item) => (
                <li key={item.title}>
                  <div className="flex justify-between items-center">
                    <Link
                      to={item.link}
                      onClick={() => {
                        setShowLeftSideBar(false);
                      }}
                    >
                      <button className="flex items-center text-lg font-semibold text-black">
                        {item.children.length > 0 ? (
                          openSections[item.title] ? (
                            <FaChevronDown className="mr-2" />
                          ) : (
                            <FaChevronRight className="mr-2" />
                          )
                        ) : (
                          <FaChevronRight className="mr-2 invisible" />
                        )}
                        {item.title}
                      </button>
                    </Link>
                    {item.children.length > 0 && (
                      <button
                        onClick={() => toggleSection(item.title)}
                        className={`ml-2 p-1 rounded-full ${
                          openSections[item.title]
                            ? "bg-secondary"
                            : "bg-primary"
                        }`}
                      >
                        {openSections[item.title] ? (
                          <FaMinus className="text-white" />
                        ) : (
                          <FaPlus className="text-white" />
                        )}
                      </button>
                    )}
                  </div>

                  {item.children.length > 0 && openSections[item.title] && (
                    <ul className="pl-8 mt-2 space-y-2">
                      {item.children.map((subItem) =>
                        subItem.title === "API" ? (
                          <a
                            key={subItem.title}
                            href={`${APIURL}/api/docs/`}
                            className="flex items-center text-gray-600 hover:text-blue-500 transition-colors"
                          >
                            <li className="flex items-center">
                              <FaChevronRight className="mr-2 text-xs text-gray-400" />
                              {subItem.title}
                            </li>
                          </a>
                        ) : (
                          <Link
                            key={subItem.title}
                            to={subItem.link}
                            onClick={() => {
                              setShowLeftSideBar(false);
                            }}
                            className="flex items-center text-gray-600 hover:text-blue-500 transition-colors"
                          >
                            <li className="flex items-center">
                              <FaChevronRight className="mr-2 text-xs text-gray-400" />
                              {subItem.title}
                            </li>
                          </Link>
                        )
                      )}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}

      <Outlet />
    </div>
  );
}

export default LayoutMain;
