import {
  Box,
  Container,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Atlearn_Logo from "src/images/logo/atlearn_logo.png";
import Iconify from "../iconify/Iconify";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "src/Redux/userSlice";
import { PopupModal } from "react-calendly";
import siteSetting from "src/utils/siteSetting";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";
import MainButton from "../Button/MainButton/MainButton";

function NewNavBar() {
  const dispatch = useDispatch();
  const tabsView = useMediaQuery("(max-width:992px)");
  const phonesView = useMediaQuery("(max-width:480px)");
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const { user } = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [sitesetting, setSiteSetting] = useState(null);
  const APIURL = window.location.origin;
  useEffect(() => {
    // Call fetchData function when the component mounts
    const getData = async () => {
      try {
        const result = await siteSetting();
        if (result) {
          setSiteSetting(result);
        }

        // Handle the data as needed
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (user?.user) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [user]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("subscriptionExpired");
    handleClose();
    dispatch(setUser(""));
    navigate("/login", { replace: true });
  };

  const redirectToApi = () => {
    window.open(`${APIURL}/api/docs/`, "_blank");
  };

  return (
    <>
      <PopupModal
        url="https://calendly.com/farlanes/30min"
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
      <div
        style={{
          background: " #F5F6F7",
          boxShadow: "0px 4px 4px 0px #0000000D",
          padding: "10px",
          // borderBottom: "1px solid #DFE2E7",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 999,
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            // mt={2}
          >
            <Box onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
              <img src={Atlearn_Logo} alt="Atlearn_Logo" ></img>
            </Box>

            {tabsView || phonesView ? (
              <>
                <Box>
                  <IconButton
                    onClick={handleOpen}
                    sx={{
                      // mr: 1,
                      color: "text.primary",
                      // display: { lg: "none" },
                    }}
                  >
                    <Iconify icon="eva:menu-2-fill" />
                  </IconButton>
                </Box>
                <Popover
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  PaperProps={{
                    sx: {
                      p: 0,
                      mt: 1.5,
                      ml: 0.75,
                      width: 180,
                      "& .MuiMenuItem-root": {
                        typography: "body2",
                        borderRadius: 0.75,
                      },
                    },
                  }}
                >
                  <>
                    <Box sx={{ my: 1.5, px: 2.5 }}></Box>
                    <Divider sx={{ borderStyle: "dashed" }} />
                    <MenuItem onClick={() => navigate("/")} sx={{ m: 1 }}>
                      Home
                    </MenuItem>
                    <Divider sx={{ borderStyle: "dashed" }} />
                    {user?.user?.role?.name === "Administrator" && (
                      <>
                        <MenuItem
                          onClick={() => navigate("/organization/dashboard")}
                          sx={{ m: 1 }}
                        >
                          Dashboard
                        </MenuItem>
                        <Divider sx={{ borderStyle: "dashed" }} />
                      </>
                    )}

                    {user?.user && (
                      <>
                        <MenuItem
                          onClick={() => navigate("/room")}
                          sx={{ m: 1 }}
                        >
                          My Room
                        </MenuItem>

                        <Divider sx={{ borderStyle: "dashed" }} />
                        <MenuItem
                          onClick={() => navigate("/settings/profile")}
                          sx={{ m: 1 }}
                        >
                          My Profile
                        </MenuItem>
                        <Divider sx={{ borderStyle: "dashed" }} />
                      </>
                    )}
                    <MenuItem
                      sx={{ mr: 1 }}
                      onClick={() => navigate("/features")}
                    >
                      Features
                    </MenuItem>
                    <Divider sx={{ borderStyle: "dashed" }} />
                    <MenuItem sx={{ m: 1 }}>API</MenuItem>
                    <Divider sx={{ borderStyle: "dashed" }} />
                    <MenuItem
                      onClick={() => navigate("/pricing")}
                      sx={{ m: 1 }}
                    >
                      Pricing
                    </MenuItem>
                    <Divider sx={{ borderStyle: "dashed" }} />

                    {user?.user ? (
                      <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                        Logout
                      </MenuItem>
                    ) : (
                      <>
                        <MenuItem
                          onClick={() => navigate("/login")}
                          sx={{ m: 1 }}
                        >
                          Log in
                        </MenuItem>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        {sitesetting?.registration !== "invite" && (
                          <>
                            <MenuItem
                              onClick={() => navigate("/signup")}
                              sx={{ m: 1 }}
                            >
                              Sign up
                            </MenuItem>
                            <Divider sx={{ borderStyle: "dashed" }} />
                          </>
                        )}
                      </>
                    )}
                    <MenuItem>
                      <button
                        style={{
                          padding: "10px 20px",
                          fontSize: "16px",
                          backgroundColor: "#6D207B",
                          color: "#fff",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsOpen(true)}
                      >
                        Request Demo
                      </button>
                    </MenuItem>
                    <Divider sx={{ borderStyle: "dashed" }} />
                  </>
                </Popover>
              </>
            ) : (
              <>
                <Box>
                  <Stack direction={"row"} spacing={"62px"}>
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/")}
                    >
                      <Typography variant="subtitle2" className="headerMenu">
                        Home
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        className="headerMenu"
                        variant="subtitle2"
                        onClick={() => navigate("/features")}
                      >
                        Features
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        onClick={redirectToApi}
                        className="headerMenu"
                      >
                        API
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/pricing")}
                    >
                      <Typography variant="subtitle2" className="headerMenu">
                        Pricing
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        onClick={() => navigate("/contactus")}
                        className="headerMenu"
                      >
                        Contact
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // width: "30%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <Box>
                      <MainButton onClick={() => setIsOpen(true)} style={{ padding: "8px 20px" }}>
                        Request Demo
                      </MainButton>
                    </Box>
                    {active ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={{
                          xs: 0.5,
                          sm: 1,
                        }}
                      >
                        <AccountPopover />
                      </Stack>
                    ) : (
                      <>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Box
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => navigate("/login")}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "19.6px",
                                color: "#545962",
                              }}
                            >
                              Log in
                            </Typography>
                          </Box>
                          {sitesetting?.registration !== "invite" && (
                            <Box
                              onClick={() => navigate("/signup")}
                              sx={{
                                borderRadius: "8px",
                                border: " 1px solid #6D207B",
                                // background: "#0C56AC",
                                padding: "10px 18px",
                                cursor: "pointer",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  lineHeight: "19.6px",
                                  color: "#545962",
                                }}
                              >
                                Sign up
                              </Typography>
                            </Box>
                          )}
                        </Stack>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Container>
      </div>
    </>
  );
}

export default NewNavBar;
