import React from 'react'

const MonitoringIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.44 2H17.55C21.11 2 22 2.89 22 6.44V12.77C22 16.33 21.11 17.21 17.56 17.21H6.44C2.89 17.22 2 16.33 2 12.78V6.44C2 2.89 2.89 2 6.44 2Z" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 17.2207V22.0007" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 13H22" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.5 22H16.5" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default MonitoringIcon