import React from 'react';

const DeleteIcons = () => {
  return (
    <div style={{ marginRight: '8px' }}>
      <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 5V22H19.5V5H4.5Z" stroke="red" stroke-width="1.5" stroke-linejoin="round" />
        <path d="M10 10V16.5" stroke="red" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14 10V16.5" stroke="red" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M2 5H22" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8 5L9.6445 2H14.3885L16 5H8Z" stroke="red" stroke-width="1.5" stroke-linejoin="round" />
      </svg>
    </div>
  );
};

export default DeleteIcons;
