import { Box,TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import apiClients from "src/apiClients/apiClients";
import { toast } from "react-toastify";
import MainButton from "src/components/Button/MainButton/MainButton";
function Administration() {
  const [terms, setTerms] = useState();
  const [termsId, setTermId] = useState();
  const [privacyId, setPrivacyId] = useState();
  const [privacy, setPrivacy] = useState();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = {
      name: ["Terms", "PrivacyPolicy"],
    };
    try {
      const response = await apiClients.getSiteSettings(data);
      if (response.data) {
        response.data.forEach((item) => {
          switch (item.setting.name) {
            case "Terms":
              setTerms(item.value);
              setTermId(item.id);
              break;
            case "PrivacyPolicy":
              setPrivacy(item.value);
              setPrivacyId(item.id);
              break;
            default:
              break;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateTerms = async () => {
    const data = {
      value: terms,
    };
    try {
      const response = await apiClients.updateSiteSettings(termsId, data);
      if (response.message) {
        toast.success(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hnadleUpdatePrivacy = async () => {
    const data = {
      value: privacy,
    };
    try {
      const response = await apiClients.updateSiteSettings(privacyId, data);
      if (response.message) {
        toast.success(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h4" gutterBottom>
          Terms & Conditions
        </Typography>
        <Typography variant="body1" gutterBottom>
          Change the terms links that appears at the bottom of the page
        </Typography>
        <Box sx={{ width: "100%" }}>
          <TextField
            sx={{ width: "100%" }}
            variant="outlined"
            placeholder="Enter link here..."
            value={terms ? terms : ""}
            onChange={(e) => setTerms(e.target.value)}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="end">
          {/* <Button
            onClick={handleUpdateTerms}
            sx={{
              border: "1px solid #0077c2",
              padding: "10px 20px",
              color: "#ffff",
              backgroundColor: "#1A73E8",
              "&:hover": {
                backgroundColor: "#0D5EBD",
              },
              marginTop: "10px",
            }}
          >
            Change URL            
          </Button> */}
          <MainButton onClick={handleUpdateTerms} style={{ marginTop: "10px" }}>
            Change URL
          </MainButton>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          Change the privacy link that appears at the bottom of the page
        </Typography>
        <Box sx={{ width: "100%" }}>
          <TextField
            sx={{ width: "100%" }}
            variant="outlined"
            placeholder="Enter link here..."
            value={privacy ? privacy : ""}
            onChange={(e) => setPrivacy(e.target.value)}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="end">
          {/* <Button
           onClick={hnadleUpdatePrivacy}
            sx={{
             
              border: "1px solid #0077c2",
              padding: "10px 20px",
              color: "#ffff",
              backgroundColor: "#1A73E8",
              "&:hover": {
                backgroundColor: "#0D5EBD",
              },
              marginTop: "10px",
            }}
          >
            Change URL
          </Button> */}
          <MainButton
            onClick={hnadleUpdatePrivacy}
            style={{ marginTop: "10px" }}
          >
            Change URL
          </MainButton>
        </Box>
      </Box>
    </>
  );
}

export default Administration;
