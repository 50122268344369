import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import "./App.css"
import { Provider } from 'react-redux';
import store from "../src/Redux/store";
// import { useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

// ----------------------------------------------------------------------
// import { KeycloakProvider } from "keycloak-react-web"
// import Keycloak from 'keycloak-js';

// const keycloakSetting = {
//   url:  process.env.REACT_APP_KEYCLOAK_URL,
//   realm:process.env.REACT_APP_KEYCLOAK_REALM,
//   clientId:process.env.REACT_APP_KEYCLOAK_CLIENTID
// };

// const authInstance = new Keycloak(keycloakSetting)

export default function App() {
  // const tawkMessengerRef = useRef();
  return (
    <Provider store={store} >
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <ToastContainer />
            {/* <KeycloakProvider client={authInstance}> */}
            <Router />
            {/* </KeycloakProvider> */}
            <div className="App">
              <TawkMessengerReact
                propertyId="65d5ebdc8d261e1b5f631752"
                widgetId="1hn5q2m97" />
            </div>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </Provider>

  );
}
