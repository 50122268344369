import {
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import apiClients from "src/apiClients/apiClients";
import MainButton from "src/components/Button/MainButton/MainButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ContactGif from "src/images/contact/contact us.gif";
function ContactUs() {
  const navigate = useNavigate();
  const formdata = {
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  };
  const [formData, setFormData] = useState();
  const [errors, setErrors] = useState({});
  useEffect(() => {
    setFormData(formdata);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async () => {
    try {
      const newErrors = {};
      const requiredFields = [
        "firstname",
        "lastname",
        "phone",
        "email",
        "subject",
        "message",
      ];
      requiredFields.forEach((field) => {
        if (
          formData[field] === undefined ||
          formData[field] === null ||
          formData[field] === ""
        ) {
          newErrors[field] = `This ${field} field is required.`;
        }
      });
      if (formData.email && !validateEmail(formData.email)) {
        newErrors.email = "Please enter a valid email address.";
      }
      if (formData.phone && !validatePhone(formData.phone)) {
        newErrors.phone = "Please enter a valid phone number.";
      }
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setErrors({});
        const response = await apiClients.contact(formData);
        setFormData("");
        if (response) {
          if (response.success === true) {
            toast.success(response.message);
            navigate("/");
          } else {
            toast.error(response.message);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };
    if (name === "email" && !validateEmail(value)) {
      newErrors.email = "Please enter a valid email address";
    } else {
      delete newErrors.email;
    }
    if (name === "phone" && !validatePhone(value)) {
      newErrors.phone = "Please enter a valid phone number";
    } else {
      delete newErrors.phone;
    }
    setErrors(newErrors);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: "#FFD600",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          pt: { xs: 8, lg: 0 },
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "0px",
                }}
              >
                <Typography
                  // style={{ marginTop: "80px" }}
                  sx={{
                    fontWeight: "600",
                    fontSize: "36px",
                    // lineHeight: "72px",
                    textAlign: "left",
                    color: "#000000",
                  }}
                >
                  Feel Free To Contact!
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    // lineHeight: "28px",
                    letterSpacing: "0.02em",
                    textAlign: "left",
                    color: "#000000",
                    paddingRight: "20%",
                  }}
                >
                  We would love to hear from you.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={ContactGif}
                  alt="mainImage"
                  width={600}
                  height={430}
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
              gap: "0px",
              height: "50px",
              backgroundColor: "#6D207B",
              mb: 20,
              borderRadius: "20px",
              pl: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "36px",
                color: "#FFFFFF",
              }}
            >
              Home - Contact Us
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box>
        <Container>
          <Box sx={{ marginTop: "-90px" }}>
            <Grid container justifyContent="center">
              <Grid item xs={10}>
                <Card sx={{ mb: 6.25 }} elevation={4}>
                  <CardContent sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="text"
                          label="First Name"
                          name="firstname"
                          placeholder="Enter Your First Name"
                          value={formData?.firstname || ""}
                          onChange={handleChange}
                          error={!!errors.firstname}
                          helperText={errors.firstname}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="text"
                          label="Last Name"
                          name="lastname"
                          placeholder="Enter Your Last Name"
                          value={formData?.lastname || ""}
                          onChange={handleChange}
                          error={!!errors.lastname}
                          helperText={errors.lastname}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="text"
                          name="email"
                          label="Email Address"
                          placeholder="Enter Your Email Address"
                          value={formData?.email || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.email}
                          helperText={errors.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="number"
                          label="Phone Number"
                          name="phone"
                          onBlur={handleBlur}
                          placeholder="Enter Your Phone Number"
                          value={formData?.phone || ""}
                          onChange={handleChange}
                          error={!!errors.phone}
                          helperText={errors.phone}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="text"
                          label="Subject"
                          name="subject"
                          placeholder="Subject"
                          value={formData?.subject || ""}
                          onChange={handleChange}
                          error={!!errors.subject}
                          helperText={errors.subject}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            name="message"
                            type="text"
                            label="Message"
                            multiline
                            fullWidth
                            placeholder="Message"
                            rows={4}
                            value={formData?.message || ""}
                            onChange={handleChange}
                            error={!!errors.message}
                            helperText={errors.message}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"end"}
                        gap={1}
                      >
                        <MainButton onClick={handleSubmit}>Submit</MainButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default ContactUs;
