

export const policySections = [
    {
      title: "Introduction",
      content:
        "Welcome to Atlearn. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our services.",
    },
    {
      title: "Information We Collect",
      content: [
        "User-Provided Information: We may collect information you provide when creating an account, such as name, email address, and other relevant details.",
        "Automatically Collected Information: We may collect information about your use of our services, including IP addresses, device information, and usage patterns.",
      ],
    },
    {
      title: "Use of Information",
      content: [
        "We use collected information to provide and improve our services, personalize user experiences, and communicate with users.",
        "Personal information is not shared with third parties, except as outlined in this policy.",
      ],
    },
    {
      title: "Sharing of Information",
      content: [
        "We may share personal information with third-party service providers for the purpose of providing and improving our services.",
        "We may disclose information if required by law or to protect our rights and safety.",
      ],
    },
    {
      title: "Security",
      content: [
        "We implement reasonable security measures to protect personal information from unauthorized access, disclosure, alteration, and destruction.",
        "However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.",
      ],
    },
    {
      title: "Cookies and Tracking Technologies",
      content: [
        "We use cookies and similar technologies to enhance user experience, analyze trends, and administer the website.",
        "Users can control cookies through browser settings, but disabling them may impact certain features.",
      ],
    },
    {
      title: "Third-Party Links",
      content:
        "Our website may contain links to third-party websites. We are not responsible for their privacy practices; users should review their policies.",
    },
    {
      title: "Children's Privacy",
      content:
        "Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children.",
    },
    {
      title: "Changes to the Privacy Policy",
      content:
        "We reserve the right to update this Privacy Policy. Changes will be communicated through the website, and continued use constitutes acceptance of the updated policy.",
    },
    {
      title: "Contact Information",
      content:
        "For questions or concerns regarding this Privacy Policy, please contact Atlearn.akra@gmail.com.",
    },
  ];