import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../LandingPange/LandingPages.css";
import bannerimg from "src/images/landingpages/bannerimg.svg";
import SecondaryButton from "src/components/Button/SecondaryButton/SecondaryButton";
import CheckIcon from "src/images/landingpages/check-circle.svg";
import RoomVideoicon from "src/images/landingpages/videocallIcon.svg";
import BigBlueButton from "src/components/techstackIocns/Bigblue button.svg";
import Express from "src/components/techstackIocns/Express JS.svg";
import Nodejs from "src/components/techstackIocns/Node Js.svg";
import Razorpay from "src/components/techstackIocns/Razor Pay.svg";
import Reactjs from "src/components/techstackIocns/React Js.svg";
import Sequilize from "src/components/techstackIocns/Sequelize ORM.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UseAuth } from "src/utils/UseAuth/UseAuth";
import { setUser } from "src/Redux/userSlice";
import apiClients from "src/apiClients/apiClients";
import Animation4 from "src/images/animation/output-onlinegiftools.gif";
import Animation5 from "src/images/animation/landingpage-5.gif";
import Prerecording from "src/components/Recordings/Prerecording";

function LandingPage() {
  const { user } = useSelector((state) => state.user);
  const [admin, setAdmin] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const navigate = useNavigate();
  const auth = UseAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    if (auth.user) {
      dispatch(setUser(auth.user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    fetchData();
    if (user?.user?.role?.name === "Administrator") {
      setAdmin(true);
    } else {
      setAdmin(false);
    }
  }, [user]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const auth = searchParams.get("auth");
    if (auth) {
      handleAuth(auth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const fetchData = async (token) => {
    try {
      const response = await apiClients.totalCount();
      if (response.data) {
        setTotalCount(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAuth = async (token) => {
    try {
      const response = await apiClients.verifyToken(token);
      if (response?.token) {
        // dispatch(setUser(response.data));
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("access_token", JSON.stringify(response.token));
        if (response?.data?.role?.name === "Administrator") {
          navigate("/organization/dashboard", { replace: true });
        } else {
          navigate("/room", { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStart = () => {
    if (user) {
      if (admin) {
        navigate("/organization/dashboard");
      } else {
        navigate("/room");
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="containers">
      {/* <NewNavBar /> */}
      <Box className="main-content">
        <Box
          style={{
            width: "100%",
            borderBottom: "1px solid #DFE2E7",
            // backgroundImage: `url(${backgroundImage})`,
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "center",
            backgroundColor: "#FCF6FE",
            paddingTop: "100px",
            paddingBottom: "100px",
          }}
        >
          {/* <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      flexDirection: "column",
                      gap: "48px",
                    }}
                  >
                    <Typography
                      style={{ marginTop: "80px" }}
                      sx={{
                        fontWeight: 500,
                        fontSize: "56px",
                        lineHeight: "72px",
                        textAlign: "left",
                        color: "#40444B",
                      }}
                    >
                      Enhance your video meetings using Farlanes for a superior
                      experience
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 400,
                        lineHeight: "28px",
                        letterSpacing: "0.02em",
                        textAlign: "left",
                        color: "#545962",
                        paddingRight: "20%",
                      }}
                    >
                      Elevate your virtual experience as we redefine the
                      standards for premium interactions, providing you with a
                      seamless and enriched journey in every meeting. Join us in
                      shaping a new era of excellence through our meticulously
                      crafted, high-quality virtual gatherings.
                    </Typography>
                    <Box>
                      {!user?.user && (
                        <SecondaryButton onClick={() => navigate("/signup")}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "16px",
                              lineHeight: "24px",
                              // color: "#6D207B",
                            }}
                          >
                            Sign Up for free
                          </Typography>
                        </SecondaryButton>
                      )}

                      <MainButton onClick={handleStart}>
                        Start Meeting
                      </MainButton>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img src={Animation4} alt="mainImage" />
                </Box>
              </Grid>
            </Grid>
          </Container> */}
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      // height: "476px",
                      borderRadius: "250px 200px 30px 250px",
                      backgroundColor: "#490d59",
                      padding: { xs: "50px", sm: "85px", md: "45px" },
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        // fontSize: "56px",
                        fontSize: `calc(20px + 1vw)`,
                        // lineHeight: "72px",
                        lineHeight: `calc(1.2 * (12px + 2vw))`,
                        textAlign: "center",
                        color: "white",
                        marginBottom: "20px",
                      }}
                    >
                      Enhance your video meetings using atlearn for a superior
                      experience
                    </Typography>
                    <Typography
                      sx={{
                   
                        fontSize: ["16px", "20px"], 
                        fontWeight: 400,
                        lineHeight: ["24px", "28px"], 
                        letterSpacing: "0.02em",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Elevate your virtual experience as we redefine the
                      standards for premium interactions, providing you with a
                      seamless and enriched journey in every meeting. Join us in
                      shaping a new era of excellence through our meticulously
                      crafted, high-quality virtual gatherings.
                    </Typography>
                    <Box ml={10} mt={2}>
                      {!user?.user && (
                        <SecondaryButton
                          onClick={() => navigate("/signup")}
                          style={{
                           width:"160px",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "white",
                            },
                            marginTop:"10px"
                            
                          }}
                        >
                          Sign Up for free
                        </SecondaryButton>
                      )}

                      <SecondaryButton
                        onClick={handleStart}
                        style={{   width:"160px",backgroundColor: "#E8063C", color: "white",marginTop:"10px" }}
                      >
                        Start Meeting
                      </SecondaryButton>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      left: "40px",
                      top: "70px",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 0px 60px #FFD600",
                      height: "200px",
                      width: "200px",
                    }}
                  ></Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    position: "relative",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img src={bannerimg} alt="mainImage" style={{ zIndex: 1 }} />
                  <img
                    src={Animation4}
                    alt="mainImage"
                    style={{
                      position: "absolute",
                      top: -10,
                      left: 0,
                      zIndex: 2,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ borderBottom: "1px solid #DFE2E7" }}>
          <Container maxWidth="xl">
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={Animation5}
                      alt="classImage"
                      style={{ maxWidth: "75%", height: "auto" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "32px",
                      }}
                    >
                      <Box sx={{ paddingRight: "15%" }}>
                        <Typography
                          className="headingTypography"
                          textAlign={"start"}
                        >
                          Experience stunning video quality with support for HD
                        </Typography>
                      </Box>
                      <Box>
                        <Stack direction={"row"} spacing={"28px"}>
                          <img src={CheckIcon} alt="CheckIcon" />
                          <Typography
                            className="contendTypography"
                            textAlign={"start"}
                            sx={{ paddingRight: "25%" }}
                          >
                            atlearn assistance, streamlines communications,
                            fosters connections, and promotes more effective
                            collaboration in boardrooms, classrooms, operating
                            rooms, and all other environments.
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack direction={"row"} spacing={"28px"}>
                          <img src={CheckIcon} alt="CheckIcon" />
                          <Typography
                            className="contendTypography"
                            textAlign={"start"}
                            sx={{ paddingRight: "25%" }}
                          >
                            Seize control of your meetings with powerful host
                            and participant controls, flexible recording
                            options, and personalized entry policies.
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Stack direction={"row"} spacing={"28px"}>
                          <img src={CheckIcon} alt="CheckIcon" />
                          <Typography
                            className="contendTypography"
                            textAlign={"start"}
                            sx={{ paddingRight: "25%" }}
                          >
                            Leverage chat features, sharedNotes, breakout rooms, and
                            unlock the ability to record sessions for a
                            comprehensive and interactive experience.
                          </Typography>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box sx={{ borderBottom: "1px solid #DFE2E7" }}>
          <Container maxWidth="xl">
            <Box>
              <Box
                sx={{
                  // height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "5%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "19.6px",
                    color: "#6D207B",
                  }}
                >
                  FEATURES
                </Typography>
                <Typography
                  className="headingTypography"
                  sx={{ textAlign: "center" }}
                >
                  Indulge in remarkable video clarity for an immersive visual
                  experience.
                </Typography>
              </Box>

          
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "5%",
                  marginBottom: "5%",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", alignItems: "stretch" }}
                >
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Box
                      sx={{
                        height: "100%",
                        border: "1px solid #B4CDEB",
                        borderRadius: "8px",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 1,
                      }}
                    >
                      <Box>
                        <img src={RoomVideoicon} alt="RoomVideoicon" />
                      </Box>

                      <Typography className="featurheading">
                        Join Room
                      </Typography>
                      <Typography className="contendTypography">
                        Whether you have a team of 2 or 200, our shared team
                        inboxes keep everyone on the same page and in the loop.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Box
                      sx={{
                        height: "100%",
                        border: "1px solid #B4CDEB",
                        borderRadius: "8px",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 1,
                      }}
                    >
                      <Box>
                        <img src={RoomVideoicon} alt="RoomVideoicon" />
                      </Box>

                      <Typography className="featurheading">
                        Create Room
                      </Typography>
                      <Typography className="contendTypography">
                        Craft dynamic remote and hybrid learning ecosystems,
                        empower educators and learners, and foster greater
                        equity in educational access.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Box
                      sx={{
                        height: "100%",
                        border: "1px solid #B4CDEB",
                        borderRadius: "8px",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 1,
                      }}
                    >
                      <Box>
                        <img src={RoomVideoicon} alt="RoomVideoicon" />
                      </Box>

                      <Typography className="featurheading">
                        Stay Connected
                      </Typography>
                      <Typography className="contendTypography">
                        Unify your teams and optimize communication flows with
                        seamless instant messaging integrated within the
                        atlearn ecosystem.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {!user?.user && (
                <Box
                  sx={{
                    borderRadius: "16px",
                    padding: " 48px ",
                    backgroundColor: "#6D207B",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "5%",
                  }}
                >
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "29px",
                            lineHeight: "40.6px",
                            color: "#F5F6F7",
                            textAlign: "start",
                          }}
                        >
                          Start your free 14 days trial
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      item
                      xs={12}
                      md={6}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          <Button
                            onClick={() => navigate("/pricing")}
                            sx={{
                              border: "1px solid #DFE2E7",
                              borderRadius: "8px",
                              padding: "8px 14px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "500",
                                fontSize: "14px",
                                lineHeight: "19.6px",
                                paddingRight: "10px",
                                color: "#F5F6F7",
                              }}
                            >
                              Learn more
                            </Typography>
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            onClick={() => navigate("/signup")}
                            sx={{
                              border: "1px solid #6D207B",
                              borderRadius: "8px",
                              padding: "8px 14px",
                              backgroundColor: "#F9F5FF",
                              "&:hover": {
                                backgroundColor: "#F9F5FF",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "500",
                                fontSize: "14px",
                                lineHeight: "19.6px",
                                paddingRight: "10px",
                                color: "#6D207B",
                              }}
                            >
                              Sign Up for free
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box></Box>
                </Box>
              )}
            </Box>
          </Container>
        </Box>
        <Box>
          <Container maxWidth="xl">
            <Box sx={{ marginBottom: "5%" }}>
              <Box
                sx={{
                  // height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "5%",
                }}
              >
                <Prerecording />
              </Box>
              <Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "35px",
                      fontWeight: 500,
                      lineHeight: "49px",
                      color: "#545962",
                      textAlign: "center",
                    }}
                  >
                    Protect your meetings with end-to-end encryption
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "33.6px",
                      color: "#545962",
                      textAlign: "center",
                    }}
                  >
                    Delight in remarkable video clarity.
                  </Typography>
                  <Box
                    sx={{
                      border: "1px solid #B4CDEB",
                      borderRadius: "8px",
                      padding: "64px",
                      marginTop: "5%",
                      marginBottom: "5%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <Grid container spacing={2}>
                        {totalCount?.map((item, index) => (
                          <Grid key={index} item xs={12} md={4}>
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "56px",
                                  lineHeight: "72px",
                                  textAlign: "center",
                                  color: "#6D207B",
                                }}
                              >
                                {item?.count}
                              </Typography>
                              <Typography
                                className="contendTypography"
                                textAlign={"center"}
                              >
                                {item?.name}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack direction={"row"} spacing={2}>
                      <Box>
                        <img src={BigBlueButton} alt="Messanger" />
                      </Box>
                      <Box>
                        <img src={Express} alt="Messanger" />
                      </Box>

                      <Box>
                        <img src={Nodejs} alt="Messanger" />
                      </Box>
                      <Box>
                        <img src={Razorpay} alt="Messanger" />
                      </Box>
                      <Box>
                        <img src={Reactjs} alt="Messanger" />
                      </Box>
                      <Box>
                        <img src={Sequilize} alt="Messanger" />
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      {/* <Footer/> */}
    </div>
  );
}

export default LandingPage;
