import React, { useState } from "react";
import "./Faq.css";
import Header from "../../components/Header";
import Footer from "src/components/Footer";
import { FaArrowDownLong } from "react-icons/fa6";
const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is an LMS?",
      answer:
        "An LMS, or Learning Management System, is a software application for the administration, documentation, tracking, reporting, automation, and delivery of educational courses, training programs, or learning and development programs.",
    },
    {
      question: "What is a virtual classroom?",
      answer:
        "A virtual classroom is an online learning environment that allows teachers and students to communicate, interact, collaborate, and discuss learning materials and activities in real-time.",
    },
    {
      question: "How do I sign up?",
      answer:
        "You can sign up by clicking the Sing Up button on the homepage and filling out the registration form with your details.",
    },
    {
      question: "What are the system requirements for using the LMS and virtual classroom?",
      answer:
        "You need a modern web browser (like Chrome, Firefox, or Safari), a stable internet connection, and a device with audio and video capabilities for virtual classrooms.",
    },
    {
      question: "How do I enroll in a course?",
      answer:
        "Browse the course catalog, select the course you are interested in, and click the 'Enroll' button. Follow the on-screen instructions to complete your enrollment.",
    },
    {
      question: "How do I access my courses?",
      answer:
        "After logging in, navigate to the 'My Courses' section from your dashboard to see and access all the courses you are enrolled in.",
    },
    {
      question: "How do I join a virtual classroom session?",
      answer:
        "Go to the course page and click on the link for the virtual classroom session at the scheduled time. Ensure your microphone and camera are working correctly before joining.",
    },
    {
      question: "What payment methods are accepted?",
      answer:
        "We accept major credit/debit cards, PayPal, and other secure payment gateways.",
    },
    {
      question: "Can I access the LMS and virtual classroom on mobile devices?",
      answer:
        " Yes, our LMS and virtual classroom are mobile-friendly and can be accessed on smartphones and tablets through a web browser or dedicated app.",
    },
    {
      question: "How do I update my profile information?",
      answer:
        "Log in to your account, go to the 'Profile' section, and edit your information as needed.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <Header backgroundImage={"/cover_images/faq.svg"}  pageTitle={"FAQ"}/>

      <div>
        <div className="text-neutral-700 overflow-x-hidden">
          <div>
            <section className="pt-8" id="section-1">
              <div className="flex m-auto">
                <div className="flex min-h-[0.06rem] md:w-full" id="div-18">
                  <div className="content-start flex-wrap flex w-full">
                    <section className="w-full">
                      <div className="flex max-w-[82.50rem] m-auto" id="div-19">
                        <div
                          className="flex min-h-[0.06rem] md:w-full  lg:w-full"
                          id="div-20"
                        >
                          <div
                            className="content-center items-center flex-wrap px-3.5 flex w-full"
                            id="div-21"
                          >
                            <div
                              className="flex-col justify-center w-full mb-5 text-6xl text-black font-semibold capitalize"
                              style={{
                                alignItems: "initial",
                              }}
                            >
                              <div className="-mb-5">
                                <div
                                  className="mb-[calc(60px_-_22px)]"
                                  id="div-22"
                                >
                                  <h3 className="-mt-1 mb-3">
                                    Frequently Asked Questions
                                  </h3>
                                </div>
                              </div>
                            </div>

                            <div
                              className="flex-col justify-center w-full "
                              style={{
                                alignItems: "initial",
                              }}
                            >
                              <div>
                                {faqs.map((faq, index) => (
                                  <div>
                                    <div
                                      style={{
                                        borderRadius: "30px 50px 30px 50px",
                                      }}
                                      className="rounded-bl-3xl rounded-br-3xl rounded-tl-3xl rounded-tr-3xl mb-5 border-4 border-zinc-300 border-solid  w-full "
                                    >
                                      <div
                                        style={{
                                          borderRadius: "30px 50px 30px 50px",
                                          padding: "15px",
                                        }}
                                        className=" flex justify-between gap-1 hover:text-white  hover:bg-fuchsia-950  bg-[#FAF6F0] items-center rounded-bl-3xl cursor-pointer text-lg md:text-xl lg:text-2xl leading-7 font-medium  w-full  lg:h-16 "
                                        //  className="text-white bg-fuchsia-950 items-center rounded-bl-3xl cursor-pointer text-[1.38rem] leading-7 font-medium py-5 pl-9 pr-16 w-[80.63rem] h-16 -m-1"
                                        id="button-1"
                                        onClick={() => toggleFAQ(index)}
                                      >
                                        <span>{faq.question}</span>

                                        <span
                                          className=" text-xl group-hover:bg-secondary duration-300   text-center w-12 h-12 border-2 border-neutral-300 border-solid rounded-full flex justify-center items-center"
                                          id="a-1"
                                        >
                                          <FaArrowDownLong className="hover:text-white  " />
                                        </span>
                                      </div>
                                      {activeIndex === index && (
                                        <div className="py-5 px-9 " id="div-23">
                                          {faq.answer ||
                                            "Answer not available."}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section />
        </div>
      </div>

      <Footer />

      {/* <div>
        <div className="text-neutral-700 overflow-x-hidden">
          <div>
            <section className="pt-28" id="section-1">
              <div className="flex m-auto">
                <div className="flex min-h-[0.06rem] w-full" id="div-18">
                  <div className="content-start flex-wrap flex w-full">
                    <section className="w-full">
                      <div className="flex max-w-[82.50rem] m-auto" id="div-19">
                        <div
                          className="flex min-h-[0.06rem] w-full"
                          id="div-20"
                        >
                          <div
                            className="content-center items-center flex-wrap px-4 flex w-full"
                            id="div-21"
                          >
                            <div
                              className="flex-col justify-center w-full mb-5 text-4xl md:text-5xl lg:text-6xl text-black font-semibold capitalize"
                              style={{ alignItems: "initial" }}
                            >
                              <div className="-mb-5">
                                <div
                                  className="mb-[calc(3rem_-_1.375rem)]"
                                  id="div-22"
                                >
                                  <h3 className="-mt-1 mb-3">
                                    Frequently Asked Questions
                                  </h3>
                                </div>
                              </div>
                            </div>

                            <div
                              className="flex-col justify-center w-full"
                              style={{ alignItems: "initial" }}
                            >
                              <div>
                                {faqs.map((faq, index) => (
                                  <div>
                                    <div
                                      style={{
                                        borderRadius: "30px 50px 30px 50px",
                                      }}
                                      className="rounded-3xl mb-5 border-4 border-zinc-300 border-solid"
                                    >
                                      <button
                                        style={{
                                          borderRadius: "30px 50px 30px 50px",
                                        }}
                                        className="text-white bg-fuchsia-950 items-center rounded-3xl cursor-pointer text-lg md:text-xl lg:text-2xl font-medium py-5 px-9 w-full"
                                        id="button-1"
                                        onClick={() => toggleFAQ(index)}
                                      >
                                        How can I enroll students and manage
                                        their progress within the online
                                        classroom platform?
                                      </button>
                                      {activeIndex === index && (
                                        <div className="py-5 px-9 " id="div-23">
                                          {faq.answer ||
                                            "Answer not available."}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section />
        </div>
      </div> */}
    </>
  );
};

export default FAQ;
