import Accordion from "src/components/Accordion";
import Features from "src/components/Features";
import Footer from "src/components/Footer";
import Header from "src/components/Header";
import HeaderText from "src/components/HeaderText";
import InfoCard from "src/components/InfoCard";
import InfoSection from "src/components/InfoSection";
import Paragraph from "src/components/Paragraph";
import Benifits from "src/assets/images/Services/teachers/Benifits.webp";
//data files
import {
  overView,
  courseManagement,
  StudentEngagement,
  AssessmentGrading,
  CommunicationTools,
  ResourceLibrary,
  CustomizationPersonalization,
  OnlineVirtualClasses,
  courseManagementInfo,
  StudentEngagementInfo,
} from "src/Data/teachersData";

const Teachers = () => {
  return (
    <div>
      <Header
        backgroundImage={"/cover_images/teacher.svg"}
        // backgroundImage={"/cover_images/ExperiencetheBestofPersonalized.png"}
        subTitle={"Learn anytime, anywhere—unlock your potential with Atlearn's online classes"}
        cta={"Get Started Now"}
        pageTitle={"Teachers"}
      />
      <div >
        <InfoSection info={overView} />
      </div>

      <div>
        <InfoSection info={courseManagementInfo} text_center={true} />
        <InfoCard cardData={courseManagement} />
      </div>

      <InfoSection info={StudentEngagementInfo} text_center={true} />
      <div className="py-1 sm:px-8 lg:px-60 -mt-8">
        {/* <Accordion items={courseManagementFeatures} /> */}
        <Features features={StudentEngagement} />
      </div>

      <div className="mt-8">
        <InfoSection info={AssessmentGrading} imageSide />
      </div>

      <section className="text-neutral-700  relative " id="section-1">
        <div className="relative flex max-w-[82.50rem] m-auto flex-wrap px-3.5">
          <div className="flex-col justify-center w-full mb-[calc(60px_-_22px)]">
            <HeaderText>Communication Tools</HeaderText>
            <Paragraph>
              Facilitate seamless communication between educators, students, and
              parents via messaging and announcements.
            </Paragraph>
          </div>
        </div>
        <div >
          <Accordion items={CommunicationTools} />
        </div>
      </section>

      <div className="mt-8">
        <InfoSection info={ResourceLibrary} />
      </div>

      <div className="">
        <InfoSection info={CustomizationPersonalization} imageSide />
      </div>

      <div className=" mb-2">
        {/* <InfoCard cardData={OnlineVirtualClasses} cardCount={4} /> */}
        <InfoSection info={OnlineVirtualClasses} />
      </div>

      <div
        style={{
          backgroundImage: `url(${Benifits})`,
          backgroundSize: "cover", // Ensures the image covers the entire div
          backgroundPosition: "center", // Centers the image
          backgroundRepeat: "no-repeat",
        }}
        className="mt-10  h-[150px]  md:h-[300px] lg:h-[600px] w-full"
        // className="mt-10  h-[150px]   w-full"
      ></div>

      <Footer />
    </div>
  );
};

export default Teachers;
