import {
  Box,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainButton from "src/components/Button/MainButton/MainButton";
function OnlyJoin() {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (user) {
      console.log(user);
      if (user?.permission?.["CreateRoom"] !== "false") {
        navigate("/room");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleJoin = () => {
    if (url) {
      // Use a regular expression to extract the desired part
      const match = url.match(/\/room(.*?)\/join/);

      if (match && match[1]) {
        const extractedString = match[1];
        if (extractedString) {
          navigate(`/room${extractedString}/join`);
        }
      } else {
        console.log("Pattern not found in the URL");
      }
    } else {
      toast.error("Enter Meeting Url");
    }
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={9} lg={7}>
          <Box
            sx={{
              backgroundColor: "",
              width: "100%",
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginTop: "20px",
                background: "rgb(255, 255, 255)",
                minHeight: "30vh",
                width: "100%",
                borderRadius: "12px",
                boxShadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              }}
            >
              <Box sx={{ p: 4 }}>
                <Typography variant="h3">Enter Meeting URL</Typography>
                <Typography>
                  Please enter the URL of your atlearn meeting in the field
                  below
                </Typography>
              </Box>

              <Divider />
              <Box>
                <Container></Container>
                <Container maxWidth="md">
                  <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Grid item xs={8}>
                      <Box mt={1}>
                        <TextField
                          placeholder="Enter meeting URL"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid mt={1} item xs={4}>
                      <MainButton onClick={handleJoin} style={{ width: "80%" }}>
                        Join
                      </MainButton>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default OnlyJoin;
