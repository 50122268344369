import React from 'react'

const ViewIcon = () => {
    return (
        <div style={{ marginRight: '8px' }}>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 18C17.5229 18 22 12 22 12C22 12 17.5229 6 12 6C6.47715 6 2 12 2 12C2 12 6.47715 18 12 18Z" stroke="#6D207B" stroke-width="1.5" stroke-linejoin="round" />
          <path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" stroke="#6D207B" stroke-width="1.5" stroke-linejoin="round" />
        </svg>
      </div>
    )
}

export default ViewIcon
