import React from 'react'

const ServerRoomIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20 18C21.26 16.33 22 14.25 22 12C22 9.75 21.26 7.67 20 6" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 6C2.74 7.67 2 9.75 2 12C2 14.25 2.74 16.33 4 18" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.8008 15.6004C17.5508 14.6004 18.0008 13.3504 18.0008 12.0004C18.0008 10.6504 17.5508 9.40039 16.8008 8.40039" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.20001 8.40039C6.45001 9.40039 6 10.6504 6 12.0004C6 13.3504 6.45001 14.6004 7.20001 15.6004" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default ServerRoomIcon
