import Header from "../components/Header";
import HeaderText from "../components/HeaderText";
import InfoSection from "../components/InfoSection";
import Paragraph from "../components/Paragraph";
import onlineClassImg from "src/assets/images/about/online_classroom.webp";
import LiveMeetingImg from "src/assets/images/about/live_meeting.webp";
import InnovationImg from "src/assets/images/about/innovative_slutions.webp";
import ElarnImg from "src/assets/images/about/e_learning.webp";
import learningImg from "src/assets/images/about/continuous_learning.webp";
import teacherIcon from "../assets/images/about/noun-teacher.png";
import SchoolIcon from "../assets/images/about/school.svg";
import studentIcon from "../assets/images/about/students.svg";
import CoursesIcon from "../assets/images/about/courses.svg";
import Footer from "src/components/Footer";
import {
  onlineLearningSolutions,
  liveConferences,
} from "src/Data/aboutPageData";
const About = () => {
  return (
    <div>
      <Header
        backgroundImage={"/cover_images/about.svg"}
        pageTitle={"About"}
        subTitle={
          "Redefining learning with technology that adapts to your needs and helps you thrive"
        }
        cta={"Explore Our Solutions"}
      />

      <div className="mt-20">
        <InfoSection info={onlineLearningSolutions} />
        <div className="">
          <InfoSection info={liveConferences} imageSide="left" />
        </div>
      </div>

      <div>
        <section className="text-neutral-700 relative w-full mt-8">
          <div className="relative flex max-w-[82.50rem] m-auto" id="div-1">
            <div className="relative flex min-h-[0.06rem] md:w-full" id="div-2">
              <div className="content-start flex-wrap px-4 relative flex w-full">
                <div
                  className="flex-col justify-center relative w-full"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div>
                    <div className="text-center mb-[calc(60px_-_22px)]">
                      <HeaderText>Innovative Solutions</HeaderText>
                      <Paragraph>
                        Revolutionizing online education with cutting-edge
                        technology.
                      </Paragraph>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div>
        <section className="text-neutral-700 w-full">
          <div
            className="flex max-w-[82.50rem] m-auto flex-col md:flex-col lg:flex-row  items-center justify-center"
            id="div-1"
          >
            <div
              className="flex min-h-[0.06rem] w-full md:w-full  lg:w-[33.33%]"
              id="div-2"
            >
              <div
                className="content-center items-center  px-4  flex flex-col md:flex-row lg:flex-col w-full"
                id="div-3"
              >
                <div
                  className="flex-col justify-center w-full mb-5"
                  id="div-4"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div>
                    <div className="items-center flex mb-8" id="div-6">
                      <div className="w-28 mr-5 rounded-full" id="div-7">
                        <div className="w-full h-full rounded-full overflow-hidden p-1.5">
                          <img
                            alt=""
                            className="w-24 h-24 object-cover max-w-[initial] rounded-full"
                            src={onlineClassImg}
                          />
                        </div>
                      </div>

                      <div className="flex-grow">
                        <h3
                          className="text-black text-3xl font-fredoka font-semibold -mt-1 mb-3"
                          id="h3-1"
                        >
                          Online Classroom
                        </h3>

                        <div>
                          <ul className="list-none -mb-1 font-fredoka">
                            <li className="items-center flex mb-1" id="li-1">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Accessibility
                            </li>

                            <li className="items-center flex mb-1" id="li-2">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Interactivity
                            </li>

                            <li className="items-center flex mb-1" id="li-3">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Engagement
                            </li>

                            <li className="items-center flex mb-1" id="li-4">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Flexibility
                            </li>
                          </ul>
                        </div>
                      </div>
                      {"        "}
                    </div>
                  </div>
                </div>

                <div
                  className="flex-col justify-center w-full"
                  id="div-8"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div id="div-9">
                    <div className="items-center flex mb-8" id="div-10">
                      <div className="w-28 mr-5 rounded-full" id="div-11">
                        <div className="w-full h-full rounded-full overflow-hidden p-1.5">
                          <img
                            alt=""
                            className="w-24 h-24 object-cover max-w-[initial] rounded-full"
                            src={LiveMeetingImg}
                          />
                        </div>
                      </div>

                      <div className="flex-grow">
                        <h3
                          className="text-black text-3xl font-semibold -mt-1 mb-3 font-fredoka"
                          id="h3-2"
                        >
                          Live Meeting
                        </h3>

                        <div>
                          <ul className="list-none -mb-1 font-fredoka">
                            <li className="items-center flex mb-1" id="li-5">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Real-time Collaboration
                            </li>

                            <li className="items-center flex mb-1" id="li-6">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Seamless Connectivity
                            </li>

                            <li className="items-center flex mb-1" id="li-7">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Multimedia Integration
                            </li>

                            <li className="items-center flex mb-1" id="li-8">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Customizable Features
                            </li>
                          </ul>
                        </div>
                      </div>
                      {"        "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="md:flex min-h-[0.06rem] md:w-[32.703%]  hidden  lg:w-full"
              id="div-12"
            >
              <div className="content-center items-center flex-wrap px-4 flex w-full">
                <div
                  className="flex-col justify-center text-center w-full"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div className="-mb-3">
                    <img
                      alt=""
                      className="align-middle inline-block max-w-full rounded-full object-cover w-80 h-80"
                      id="img-1"
                      src={InnovationImg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex min-h-[0.06rem]  w-full md:w-full  lg:w-[33.33%]"
              id="div-13"
            >
              <div className="content-center items-center  px-4 flex flex-col md:flex-row lg:flex-col w-full">
                <div
                  className="flex-col justify-center w-full mb-5"
                  id="div-14"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div id="div-15">
                    <div className="items-center flex mb-8" id="div-16">
                      <div className="w-28 mr-5 rounded-full" id="div-17">
                        <div className="w-full h-full rounded-full overflow-hidden p-1.5">
                          <img
                            alt=""
                            className="w-24 h-24 object-cover max-w-[initial] rounded-full"
                            src={ElarnImg}
                          />
                        </div>
                      </div>

                      <div className="flex-grow">
                        <h3
                          className="text-black text-3xl font-semibold -mt-1 mb-3 font-fredoka"
                          id="h3-3"
                        >
                          E-Learning
                        </h3>

                        <div>
                          <ul className="list-none -mb-1 font-fredoka">
                            <li className="items-center flex mb-1" id="li-9">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Comprehensive Resources
                            </li>

                            <li className="items-center flex mb-1" id="li-10">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Personalized Learning Paths
                            </li>

                            <li className="items-center flex mb-1" id="li-11">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Interactive Assessments
                            </li>

                            <li className="items-center flex mb-1" id="li-12">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Progress Tracking
                            </li>
                          </ul>
                        </div>
                      </div>
                      {"        "}
                    </div>
                  </div>
                </div>

                <div
                  className="flex-col justify-center w-full"
                  id="div-18"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div id="div-19">
                    <div className="items-center flex mb-8" id="div-20">
                      <div className="w-28 mr-5 rounded-full" id="div-21">
                        <div className="w-full h-full rounded-full overflow-hidden p-1.5">
                          <img
                            alt=""
                            className="w-24 h-24 max-w-[initial] rounded-full"
                            src={learningImg}
                          />
                        </div>
                      </div>

                      <div className="flex-grow">
                        <h3
                          className="text-black text-3xl font-semibold -mt-1 mb-3 font-fredoka"
                          id="h3-4"
                        >
                          Continuous Learning
                        </h3>

                        <div>
                          <ul className="list-none -mb-1 font-fredoka">
                            <li className="items-center flex mb-1" id="li-13">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Skill Development Focus
                            </li>

                            <li className="items-center flex mb-1" id="li-14">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Lifelong Learning Promotion
                            </li>

                            <li className="items-center flex mb-1" id="li-15">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              Dynamic Team Collaboration
                            </li>

                            <li className="items-center flex mb-1" id="li-16">
                              <span className="mr-2 text-secondary">
                                &#x25CF;
                              </span>
                              User-Centric Approach
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="font-fredoka">
        <section className="text-neutral-700 w-full" id="section-1">
          <div className="flex max-w-[82.50rem] m-auto" id="div-1">
            <div
              className="flex min-h-[0.06rem] md:w-full  lg:w-full"
              id="div-2"
            >
              <div
                className="content-center items-center flex-wrap justify-between px-4 flex w-full"
                id="div-3"
              >
                <div
                  className="flex-col justify-center w-auto max-w-full"
                  id="div-4"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div className="mr-9" id="div-5">
                    <div className="items-center flex mb-8" id="div-6">
                      <div
                        className="text-black bg-slate-100 text-center inline-block mr-5 rounded-full"
                        id="div-7"
                      >
                        <img alt="" className="w-12 h-auto" src={CoursesIcon} />
                      </div>

                      <div className="flex-grow font-semibold">
                        <p className="text-rose-600 md:text-[5.63rem] text-[60px] leading-none">
                          20+
                        </p>

                        <p className="text-black text-lg">Courses</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="flex-col justify-center w-auto max-w-full"
                  id="div-8"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div className="mr-9" id="div-9">
                    <div className="items-center flex mb-8" id="div-10">
                      <div
                        className="text-black bg-slate-100 text-center inline-block mr-5 rounded-full"
                        id="div-11"
                      >
                        <img alt="" className="w-12 h-auto" src={studentIcon} />
                      </div>

                      <div className="flex-grow font-semibold">
                        <p className="text-rose-600 md:text-[5.63rem] text-[60px] leading-none">
                          200+
                        </p>

                        <p className="text-black text-lg">Students</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="flex-col justify-center w-auto max-w-full"
                  id="div-12"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div className="mr-9" id="div-13">
                    <div className="items-center flex mb-8" id="div-14">
                      <div
                        className="text-black bg-slate-100 text-center inline-block mr-5 rounded-full"
                        id="div-15"
                      >
                        <img alt="" className="w-14 h-auto" src={SchoolIcon} />
                      </div>

                      <div className="flex-grow font-semibold">
                        <p className="text-rose-600 md:text-[5.63rem] text-[60px] leading-none">
                          20+
                        </p>

                        <p className="text-black text-lg">Schools</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="flex-col justify-center w-auto max-w-full"
                  id="div-16"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div className="mr-9" id="div-17">
                    <div className="items-center flex mb-8" id="div-18">
                      <div
                        className="text-black bg-slate-100 text-center inline-block mr-5 rounded-full"
                        id="div-19"
                      >
                        <img alt="" className="w-20 h-auto" src={teacherIcon} />
                      </div>

                      <div className="flex-grow font-semibold">
                        <p className="text-rose-600 md:text-[5.63rem] text-[60px] leading-none">
                          23
                        </p>

                        <p className="text-black text-lg">Teachers</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default About;
