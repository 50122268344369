import DataManagement from "src/assets/images/Services/School/DataManagement.webp";
import Bulkenrollment from "src/assets/images/Services/Bulk-enrollment.webp";
import Integration from "src/assets/images/Services/School/Integration1.webp";
import Libraryatlearn from "src/assets/images/Services/School/Library-atlearn1.webp";
import Poll from "src/assets/images/Services/School/atlearn_poll.webp";
import trackIcon from "src/assets/icons/performance.png";
import scheduleIcon from "src/assets/icons/calender.png";
import WhiteBordIcon from "src/assets/icons/whiteboard.png";
import smart_scheduling from "src/assets/img/smart_scheduling.webp";
import learningLibrary from "src/assets/images/Services/School/learning_library.webp";
import signIn from "src/assets/img/sign-in.webp";
import step_verification from "src/assets/img/2_step_verification.webp";
import watchIcon from "src/assets/images/Services/watch.png";
export const studentDataManagement = {
  title: "Efficient Student Data Management At AtLearn",
  description:
    "At AtLearn, we understand the importance of efficient student data management for smooth educational procedures. Our platform offers flexible enrollment options, allowing teachers, administrators, and students to enroll in different ways. Our system also makes it easy to organize groups for classes with shared courses or different tasks. With AtLearn, educators can easily manage and access student data, promoting personalized instruction and optimal support for better learning outcomes.",
  points: [
    "Group organization for shared or differentiated tasks.",
    "Easy access to student data for educators.",
    "Personalized instruction and support for optimal outcomes.",
    "Versatile enrollment options like manual, automated, self-enrollment.",
  ],
  image: DataManagement,
};

export const bulkEnrollment = {
  title: "Bulk Enrollment With AtLearn",
  description:
    "Enhance school administration efficiency with AtLearn's bulk upload functionality. Save valuable time and effort by effortlessly uploading multiple users simultaneously. Guarantee a smooth and hassle-free experience for both administrators and users.",
  points: [
    "Simplify user enrollment processes",
    "Save valuable time for administrators",
    "Upload multiple users at once",
    "Suitable for new students, teachers, and staff members",
    "Seamless experience for all users",
    "Convenient solution for expanding institutions",
  ],
  image: Bulkenrollment,
};

export const simplifySchool = {
  title: "AtLearn - Simplify School Integration",
  description:
    "School administrators have the opportunity to effortlessly incorporate AtLearn into their current systems, courtesy of our user-friendly interface and extensive integration support. By means of simple setup and configuration, administrators can promptly introduce AtLearn to streamline the management processes of the school. From managing student data to enrolling in courses, our platform provides effortless integration, enabling administrators to enhance educational operations with utmost ease.",
  image: Integration,
};

export const atLearnBook = {
  title: "Enhanced Book Management with AtLearn",
  description:
    "In Atlearn, our book-centric feature is designed to streamline the management and distribution of educational materials. It offers a centralized platform that empowers instructors to efficiently organize, upload, and administer digital books. Users have the flexibility to categorize books into folders, swiftly search for specific content, and navigate seamlessly using bookmarks and a table of contents. The inclusion of annotation tools enables users to enhance their engagement by adding notes and highlights. Moreover, instructors can easily monitor student progress, while users can conveniently download books for offline access.",
  image: Libraryatlearn,
};

export const atLearnPoll = {
  title: "Atlearn Polls for Enhanced Learning",
  description:
    "The Atlearn polls function enhances engagement and evaluates understanding. Educators can generate questions with multiple-choice alternatives. Polls enhance the learning experience by assessing comprehension and collecting opinions. Educators can seamlessly incorporate polls into their course materials. The data insights obtained offer valuable trends in engagement and comprehension.",
  image: Poll,
};

export const courseManagementFeatures = [
  {
    id: 1,
    title: "Student Data Management",
    description:
      "Easily organize and access student information, including demographics, enrollment status, and academic records, ensuring streamlined administrative processes.",
  },
  {
    id: 2,
    title: "Assessment Management",
    description:
      "Create and administer assessments, including quizzes, tests, and assignments, with customizable options to suit your teaching objectives and track student progress effectively.",
  },
  {
    id: 3,
    title: "Attendance Tracking",
    description:
      "Monitor student attendance effortlessly, enabling you to identify patterns, address attendance issues promptly, and ensure accountability for student participation.",
  },
  {
    id: 4,
    title: "Performance Monitoring",
    description:
      "Gain insights into student performance through comprehensive analytics and reporting features, allowing you to track individual progress, identify areas for improvement, and provide targeted support as needed.",
  },
];

export const features = [
  {
    icon: WhiteBordIcon,
    title: "Teachers Availability",
    description:
      "View teachers' availability for efficient scheduling and coordination.",
  },
  {
    icon: scheduleIcon,
    title: "Schedule Interaction",
    description:
      "Plan interactions and appointments based on real-time availability.",
  },
  {
    icon: trackIcon,
    title: "Track Teacher Workload",
    description:
      "Manage teacher workload effectively with accessible scheduling information.",
  },
];
export const AtLearnExperience = {
  title: "Optimize Your AtLearn Experience With Its Features",
  description:
    "At AtLearn, transparency is of utmost importance. Our platform enables students and administrators to conveniently access teachers' availability, offering valuable information about their schedules. This feature empowers students to effectively plan their interactions and appointments, while administrators can efficiently handle teacher workload and scheduling. By fostering transparent communication and coordination, AtLearn significantly improves the teaching and learning experience for all parties involved.",
};

export const CourseManagementInfo = {
  title: "User Management",
  description:
    "User management allows you to create multiple user roles in atlearn management system. The admin role has full control over the course, while the manager role focuses on day-to-day operations. Students have access to course materials, and teachers guide and instruct them. Non-editing teachers provide specialized knowledge. Custom roles can be created to meet specific requirements. Bulk enrollment allows for the simultaneous enrollment of multiple users, saving time and effort.",
};

export const CourseManagementCards = [
  {
    imageSrc: smart_scheduling,
    color: "bg-lime-600",
    iconSrc: watchIcon,
    heading: "Smart scheduling",
    description:
      "Utilize intelligent scheduling for lesson, assignment, and quiz planning. Enhance communication with automated messages to students and parents.",
  },
  {
    imageSrc: learningLibrary,
    color: "bg-cyan-500",
    iconSrc: watchIcon,
    heading: "Learning Library",
    description:
      "Access eBooks, PDFs, videos, and audio files through our learning library on the Atlearn site, benefiting both students and instructors",
  },
  {
    imageSrc: signIn,
    color: "bg-fuchsia-950",
    iconSrc: watchIcon,
    heading: "Single sign-on",
    description:
      "Streamline access with Single Sign-On (SSO), reducing security risks. Efficiently manage user access with rapid granting or denial.",
  },
  {
    imageSrc: step_verification,
    color: "bg-orange-600",
    iconSrc: watchIcon,
    heading: "Two-Step Verification",
    description:
      "We've bolstered authentication security for added protection. Admins can enable this option for users via the security tab",
  },
];
