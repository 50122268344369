//images
import onlineclasses from "src/assets/img/online-classes.png";
import joinroom from "src/assets/images/online-classes/joinroom.png";
import overviewImage from "src/assets/images/Services/teachers/overview.webp";
import CourseCreation from "src/assets/images/Services/teachers/CourseCreation.webp";
import MultimediaIntegration from "src/assets/images/Services/teachers/MultimediaIntegration.webp";
import SchedulingCalendars from "src/assets/images/Services/teachers/SchedulingCalendars.webp";
import InteractiveForum from "src/assets/images/Services/teachers/InteractiveForum.svg";
import Realtime from "src/assets/images/Services/teachers/Realtime.svg";
import Gamification from "src/assets/images/Services/teachers/Gamification.svg";
import AssessmentGradingImg from "src/assets/images/Services/teachers/AssessmentGrading.webp";
import resourcelibrary from "src/assets/images/Services/teachers/resourcelibrary.webp";
import CustomizationPersonalizationImg from "src/assets/images/Services/teachers/CustomizationPersonalization.webp";
import onlinevirtualclass from "src/assets/images/Services/teachers/onlinevirtualclass.webp";

export const overView = {
  title: "Overview",
  description:
    "Our platform offers a comprehensive suite of tools and resources to streamline your teaching experience, enhance student engagement, and simplify classroom management. Whether you're teaching in-person, online, or hybrid classes, our LMS provides the flexibility and support you need to succeed.",
  image: overviewImage,
};

export const courseManagement = [
  {
    imageSrc: CourseCreation,
    iconSrc: onlineclasses,
    color: "bg-[#65A30D]",
    heading: "Course Creation & Organization",
    description:
      "Easily create courses by uploading syllabi, lesson plans, and course materials. Organize content into modules, units, or chapters for seamless navigation.",
  },
  {
    imageSrc: MultimediaIntegration,
    iconSrc: onlineclasses,
    color: "bg-[#06B6D4]",
    heading: "Multimedia Integration",
    description:
      "Enrich your courses with videos, audio recordings, slideshows, and interactive elements. Our platform supports various file formats, allowing you to deliver engaging content.",
  },
  {
    imageSrc: SchedulingCalendars,
    iconSrc: onlineclasses,
    color: "bg-[#4A044E]",
    heading: "Scheduling & Calendars",
    description: "Set up course schedules, due dates, and reminders.",
  },
];

export const StudentEngagement = [
  {
    icon: InteractiveForum,
    title: "Interactive Forums & Discussion Boards",
    description:
      "Foster a collaborative learning environment with discussion forums where students can share ideas, ask questions, and engage in debates.",
  },
  {
    icon: Realtime,
    title: "Real-time Quizzes & Polls",
    description:
      "Create dynamic quizzes and polls to assess understanding and gather feedback instantly. Use these tools during live sessions to keep students engaged.",
  },
  {
    icon: Gamification,
    title: "Gamification Elements",
    description:
      "Incorporate badges, leaderboards, and achievement tracking to motivate students and make learning fun. Reward participation and performance to encourage continuous improvement.",
  },
];

export const AssessmentGrading = {
  title: "Assessment & Grading",
  description:
    "Create and manage quizzes, assignments, and grading with automated tools.",
  points: [
    "Save time with automated grading for multiple-choice, true/false, and other objective assessments. Customize grading settings to fit your teaching style.",
    " Create detailed rubrics for assignments and projects, ensuring consistent and transparent grading. Provide specific feedback and comments to guide student improvement.",
    "Monitor student performance with detailed analytics. Track progress, identify trends, and adjust your teaching strategies accordingly.",
  ],
  image: AssessmentGradingImg,
};

export const CommunicationTools = [
  {
    id: 1,
    title: "Direct Messaging",
    description:
      "Communicate directly with students and parents through secure messaging. Send individual or group messages for announcements, feedback, and support.",
  },
  {
    id: 2,
    title: "Announcements & Notifications",
    description:
      "Keep everyone informed with announcements that appear on the dashboard or via email. Set up automatic notifications for assignment deadlines, new content, and more.",
  },
  {
    id: 3,
    title: "Virtual Classroom Integration",
    description:
      "Monitor student performance with detailed analytics. Track progress, identify trends, and adjust your teaching strategies accordingly.",
  },
];

export const ResourceLibrary = {
  title: "Resource Library",
  description:
    "Access and share a wide range of educational materials, including textbooks and multimedia resources.",
  points: [
    "Browse a vast library of educational materials, including textbooks, articles, and multimedia resources. Filter by subject, grade level, and more.",
    "Contribute your own resources to the library. Share lesson plans, worksheets, and other materials with colleagues or make them available to the broader teaching community.",
    "Interactive tools and quizzes integrated within the library to enhance learning experiences. Track student progress and customize assessments to meet individual learning needs.",
  ],
  image: resourcelibrary,
};

export const CustomizationPersonalization = {
  title: "Customization & Personalization",
  description:
    "Access and share a wide range of educational materials, including textbooks and multimedia resources.",
  points: [
    "Personalize your dashboard to access your favorite tools and resources quickly. Customize the student dashboard to highlight important information and upcoming events.",
    "Design individualized learning paths based on student needs and abilities. Assign differentiated tasks and resources to support diverse learning styles.",
    "Utilize adaptive learning algorithms to tailor content and assessments based on student performance. Provide targeted interventions to address learning gaps.",
  ],
  image: CustomizationPersonalizationImg,
};

export const OnlineVirtualClasses = {
  title: "Online Virtual Classes",
  description:
    " Conduct live or recorded classes with integrated video conferencing and colla.boration tools.",
  points: [
    "Conduct live classes with high-quality video and audio streaming. Record sessions for students to review later, ensuring they can revisit important concepts and lessons.",
    "Use digital whiteboards to illustrate concepts, solve problems, and collaborate with students. Save whiteboard sessions for future reference.",
    "Create breakout rooms for group discussions, projects, or peer tutoring. Manage rooms easily and switch between them to facilitate collaborative learning.",
    "Utilize tools like attendance tracking, raise hand features, and student participation controls to maintain a structured and interactive virtual classroom environment.",
  ],
  image: onlinevirtualclass,
};

export const Benefits = [
  {
    icon: joinroom,
    title: "Time Efficiency",
    description: "Automate administrative tasks and focus more on teaching.",
  },
  {
    icon: joinroom,
    title: "Enhanced Learning",
    description: "Engage students with interactive and multimedia content.",
  },
  {
    icon: joinroom,
    title: "Flexible Teaching",
    description: "Adapt to various teaching styles and methods.",
  },
];

export const courseManagementInfo = {
  title: "Course Management",
  description:
    "Organize and deliver courses efficiently, including scheduling, content distribution, and tracking progress.",
};

export const StudentEngagementInfo = {
  title:"Student Engagement",
  description:"Enhance interaction with students through discussion forums, polls, and interactive content."
}
