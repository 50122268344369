import Header from "../../components/Header";
import featuregif from "src/assets/images/online-classes/featuresIcon/features.gif";
import Features from "../../components/Features";
import Footer from "src/components/Footer";
import InfoSection from "src/components/InfoSection";
import { featuresContent } from "src/Data/featuresPageData";

const FeaturesPage = () => {
  const FeaturesInfo = {
    title: "Seamless Video Clarity: Connect and Engage with Ease",
    description:
      "Indulge in remarkable video clarity for an immersive visual experience.",
    points: ["Join Room", "Create Room", "Stay Connected"],
    image: featuregif,
  };
  return (
    <>
      <div>
        <Header
          backgroundImage={"/cover_images/features.svg"}
          pageTitle={"Features"}
          subTitle={
            "Explore cutting-edge features designed to enhance your learning experience with Atlearn"
          }
          cta={"Discover Features"}
        />
        <div className="mt-8 mb-10">
          <InfoSection info={FeaturesInfo} rounded />
        </div>
        <div style={{ minHeight: "500px" }} className="-mt-16 mb-8">
          <Features features={featuresContent} />
        </div>

        <Footer />
      </div>
    </>
  );
};

export default FeaturesPage;
