

export const termsSections = [
    {
      title: 'Acceptance of Terms',
      content: 'By accessing and using Atlearn, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, please refrain from using the Website.'
    },
    {
      title: 'Use of Services',
      content: 'Users must adhere to acceptable use policies, including refraining from engaging in any unlawful or harmful activities while using the services.'
    },
    {
      title: 'User Accounts',
      content: 'Some features may require users to create accounts. Users are responsible for maintaining the confidentiality of their account information.'
    },
    {
      title: 'User Conduct',
      content: 'Users shall not engage in harassment, discrimination, or any illegal activities during video conferencing sessions. Atlearn reserves the right to suspend or terminate user accounts for violations of conduct.'
    },
    {
      title: 'Content and Data',
      content: 'Users retain ownership of content shared during video conferencing sessions. The Website collects, stores, and uses user data under our Privacy Policy.'
    },
    {
      title: 'Intellectual Property',
      content: 'The Website and its content are protected by intellectual property laws. Users may not reproduce, distribute, or modify any content without explicit permission.'
    },
    {
      title: 'Privacy Policy',
      content: 'The collection, use, and protection of personal information are outlined in the Privacy Policy. By using the Website, you consent to the practices described in the Privacy Policy.'
    },
    {
      title: 'Service Availability',
      content: 'The Website may experience downtime or disruptions; users accept these risks. The Website is not liable for any losses or damages resulting from service interruptions.'
    },
    {
      title: 'Termination of Services',
      content: 'The Website reserves the right to terminate services for any user violating terms and conditions. Users may terminate their accounts by following the provided procedures.'
    },
    {
      title: 'Limitation of Liability',
      content: 'The Website is not liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use the services.'
    },
    {
      title: 'Changes to Terms and Conditions',
      content: 'The Website reserves the right to modify these terms and conditions. Users will be notified of changes, and continued use constitutes acceptance.'
    },
    {
      title: 'Contact Information',
      content: 'For questions or concerns regarding these terms and conditions, please contact Atlearn.akra@gmail.com.'
    }
  ];