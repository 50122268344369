import Button from "./Button";

const Header = ({
  backgroundImage,
  pageTitle,
  showBreadcumb = true,
  subTitle,
  cta,
}) => {
  return (
    <div>
      {showBreadcumb ? (
        <>
          <div
            style={{
              backgroundImage: `url(/cover_images/bannerBg.webp)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            className=" relative  h-full lg:h-[375px] w-full flex flex-col lg:flex-row  justify-between items-center  bg-[#F9DAFF] p-4 gap-2"
          >
            <div className="lg:pl-8 w-full  lg:w-1/2  h-full flex flex-col md:flex-row  justify-center items-center">
              <div className="relative flex flex-col items-center justify-center   rounded-lg lg:rounded-tl-[165px] lg:rounded-tr-[171px] lg:rounded-bl-[171px] lg:rounded-br-[40px] text-[1rem] sm:text-[1.63rem] sm:leading-8 h-auto sm:h-[250px] lg:w-[60%] sm:w-[80%] bg-fuchsia-950  bg-opacity-100 p-4">
                <h1 className="font-fredoka font-semibold text-[28px] sm:text-[2.5rem] md:text-[3rem] leading-tight text-white">
                  {pageTitle}
                </h1>
                <p className="text-white text-center font-fredoka leading-7 mt-2 sm:mt-5">
                  {subTitle ? subTitle : ""}
                </p>
                <div className="text-center mt-2  mb-2 flex justify-center">
                  <Button>{cta ? cta : "Login"}</Button>
                </div>
              </div>
            </div>
            <div
              className=" w-full lg:w-1/2 flex flex-col items-center justify-center  h-full "
              style={{
                backgroundImage: `url(/cover_images/Group24-1.png)`,
                // backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                // width:"550px"
              }}
            >
              <div className="lg:pr-8  ">
                <img
                  className="h-auto md:h-[250px]"
                  // w-full lg:w-[550px]
                  src={`${backgroundImage}`}
                  alt=""
                  style={{ objectFit: "cover", width: "100%" }}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Header;
