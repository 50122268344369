import { LinkedIn, YouTube } from "@mui/icons-material";
import { Box, Container, Typography, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
// import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
// import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
// import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
import { useNavigate } from "react-router-dom";
import apiClients from "src/apiClients/apiClients";
function NewFooter() {
  const navigate = useNavigate();
  const [terms, setTerms] = useState();
  const [privacy, setPrivacy] = useState();
  const openYouTubeTab = () => {
    window.open("https://www.youtube.com/@AkraTechServices/videos", "_blank");
  };

  const openLinkedInTab = () => {
    window.open(
      "https://www.linkedin.com/company/akra-tech-private-limited/",
      "_blank"
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = {
      name: ["Terms", "PrivacyPolicy"],
    };
    try {
      const response = await apiClients.getSiteSettings(data);
      if (response.data) {
        response.data.forEach((item) => {
          switch (item.setting.name) {
            case "Terms":
              setTerms(item.value);
              // setTermId(item.id);
              break;
            case "PrivacyPolicy":
              setPrivacy(item.value);
              // setPrivacyId(item.id);
              break;
            default:
              break;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openTermsPage = () => {
    window.location.href = terms;
  };
  const openPrivacyPage = () => {
    window.location.href = privacy;
  };
  return (
    <Box
      className="footer"
      sx={{
        backgroundColor: "#FFD600",
        minHeight: "50px",
        width: "100%",
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1 }} mt={2}>
          <Grid container spacing={2} style={{ marginTop: "50px" }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "-30px",
                  gap: 1,
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <YouTube
                    sx={{
                      color: "#545962",
                      cursor: "pointer",
                      fontSize: "36px",
                    }}
                    onClick={openYouTubeTab}
                  />
                  <LinkedIn
                    sx={{
                      color: "#545962",
                      cursor: "pointer",
                      fontSize: "36px",
                    }}
                    onClick={openLinkedInTab}
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            textAlign: "center",
            marginTop: 2,
          }}
        >
          <Typography
            className="footerPrimaryHeadings"
            sx={{
              color: "#545962 !important",
              cursor: "pointer",
              fontSize: "24px",
              fontWeight: "bold",
            }}
            onClick={() => navigate("/")}
          >
            atlearn
          </Typography>
          <Typography
            sx={{ fontWeight: 600, fontSize: "16px", color: "#545962" }}
          >
            © 2024 Copyright
          </Typography>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              borderBottom: "1px solid #ddd", // Light border separating the items
              paddingBottom: "8px", // Add some space between the border and text
            }}
          >
            <Typography
              className="footerSecondaryHeadings"
              sx={{
                color: "#545962 !important",
                cursor: "pointer",
                marginRight: "20px",
                textDecoration: "underline",
                borderRight: "1px solid #ddd",
                paddingRight: "20px",
              }}
              onClick={() =>
                terms ? openTermsPage() : navigate("/termsconditions")
              }
            >
              Terms and conditions
            </Typography>

            <Typography
              className="footerSecondaryHeadings"
              sx={{
                color: "#545962 !important",
                cursor: "pointer",
                marginRight: "20px",
                textDecoration: "underline",
                borderRight: "1px solid #ddd",
                paddingRight: "20px",
              }}
              onClick={() =>
                privacy ? openPrivacyPage() : navigate("/privacy-policy")
              }
            >
              Privacy Policy
            </Typography>
            <Typography
              className="footerSecondaryHeadings"
              sx={{
                color: "#545962 !important",
                cursor: "pointer",
                marginRight: "20px",
                textDecoration: "underline",
              }}
              onClick={() => navigate("/feedback")}
            >
              Feedback
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default NewFooter;
