import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Typography,
  Slide,
  Divider,
  TextField,
  Box,
  Container,
  Grid,
} from "@mui/material";

import Button from "@mui/material/Button";

// import FormControl from '@mui/material/FormControl';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});

function CreateRoom({ open, handleClosebox,handleCreateRoom }) {

  const [roomName, setRoomName] = useState("");

  const handleClose = () => {
    handleClosebox();
  };
  const CreateRoom = () => {
    handleCreateRoom(roomName);
    handleClosebox();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ textAlign: "center", backgroundColor: "#F5F7FB" }}>
          Create New Room
        </DialogTitle>
        <Divider />
        <DialogContent>
        <Box sx={{ mt: 6 }}>
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <Typography gutterBottom>Room Name</Typography>
                  <TextField
                    type="text"
                    placeholder="Enter a room name ...."
                    name="name"
                    // value={"test"}
                    onChange={(e) => setRoomName(e.target.value)}
                    fullWidth
                  />
                </Grid>
              
               
               
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mb: 2,
                    gap: 1,
                  }}
                >
                  <Button
                    onClick={handleClosebox}
                    sx={{
                      border: "1px solid #444444",
                      padding: "10px 20px",
                      color: "#6D207B",
                      "&:hover": {
                        backgroundColor: "#F5F7FB",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={CreateRoom}
                    sx={{
                      border: "1px solid #6D207B",
                      padding: "10px 20px",
                      color: "#ffff",
                      backgroundColor: "#6D207B",
                      "&:hover": {
                        backgroundColor: "#E8063C",
                      },
                    }}
                  >
                   Create Room
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </DialogContent>
     
      </Dialog>
    </div>
  );
}

export default CreateRoom;
