import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Autocomplete,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import apiClients from "src/apiClients/apiClients";
import { toast } from "react-toastify";
import Iconify from "src/components/iconify";
import MainButton from "src/components/Button/MainButton/MainButton";
import SecondaryButton from "src/components/Button/SecondaryButton/SecondaryButton";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});

export default function AddUser({ open, handleClose, fetchData }) {
  const { user } = useSelector((state) => state.user);
  const [roles, setRoles] = useState();
  const [name, setName] = useState("");
  const [password, setPassword] = useState();
  const [role_id, setRole_Id] = useState("");
  const [verified, setVerified] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [checkName, setCheckName] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [checkRole, setCheckRole] = useState(false);

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleClosebox = () => {
    setName("");
    setPassword("");
    setRole_Id("");
    setEmail("");
    setSelectedRole(null);
    setCheckName(false);
    setCheckPassword(false);
    setCheckEmail(false);
    setCheckRole(false);
    setVerified(false)
    handleClose();
  };

  const isEmailValid = (email) => {
    // const emailRegex = /^[a-z][a-z0-9._-]*@[a-z0-9.-]+\.[a-z]{2,}$/;
    const emailRegex = /^[a-z][a-z0-9._-]*@[a-z0-9-]+(\.[a-z]{2,}){1}$/;
    return emailRegex.test(email);
  };

  const checkAllField = () => {
    let error;
    if (!name) {
      setCheckName(true);
      error = true;
    }

    if (!password) {
      setCheckPassword(true);
      error = true;
    }

    if (email) {
      const isValid = isEmailValid(email);
      if (!isValid) {
        setCheckEmail(!isValid);
        setEmailError("Invalid Email");
        error = true;
      }
    } else {
      setCheckEmail(true);
      setEmailError("Email is required");
      error = true;
    }

    if (!selectedRole) {
      setCheckRole(true);
      error = true;
    }
    if (error) {
      return error;
    } else {
      return false;
    }
  };

  const handleCreateUser = async () => {
    try {
      const checkRequiredField = checkAllField();
      if (checkRequiredField === false) {
        const data = {
          name,
          email,
          password,
          role_id,
          verified,
          approve: true,
        };
        const response = await apiClients.createUser(data);
        if (response) {
          if (response.success === true) {
            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
          fetchData();
          handleClosebox();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await apiClients.getAllRoles();
      if (response.data) {
        // setFilteredRole(response.data);
        setRoles(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosebox}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textAlign: "center", backgroundColor: "#F5F7FB" }}>
          {"Add User"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText> */}
          <Box >
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Name *</Typography>
                  <TextField
                    type="text"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setCheckName(false);
                    }}
                    fullWidth
                    error={checkName}
                    helperText={checkName ? "Name is required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Email *</Typography>
                  <TextField
                    type="email"
                    placeholder="Enter Email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setCheckEmail(false);
                      setEmailError("");
                    }}
                    value={email}
                    fullWidth
                    error={checkEmail}
                    helperText={emailError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Password *</Typography>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setCheckPassword(false);
                    }}
                    value={password}
                    fullWidth
                    InputProps={{
                      // style: textFieldStyle,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={checkPassword}
                    helperText={checkPassword ? "Password is required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Role *</Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.name}
                    // options={roles}
                    options={user?.user?.role?.name === 'Super Admin' ? roles : roles?.filter(role => role?.name !== 'Super Admin')}
                    value={selectedRole}
                    onChange={(event, newValue) => {
                      setCheckRole(false);
                      setRole_Id(newValue?.id ? newValue?.id : "");
                      setSelectedRole(newValue ? newValue : null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Role"
                        error={checkRole}
                        helperText={checkRole ? "Role is required" : ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Verified</Typography>
                  <Select
                    sx={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={verified}
                    onChange={(e) => setVerified(e.target.value)}
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
             
                >
                  <Box
                    display={{ xs: "flex", sm: "flex" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    justifyContent={{ xs: "center", sm: "flex-end" }}
                    gap={1}
                  >
                    <SecondaryButton
                      onClick={handleClosebox}
                      sx={{
                        border: "1px solid #444444",
                        padding: "10px 20px",
                        color: "#444444",
                        "&:hover": {
                          backgroundColor: "#F5F7FB",
                        },
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                    <MainButton onClick={handleCreateUser}>Add User</MainButton>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
