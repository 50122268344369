import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  ClickAwayListener,
  Divider,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import apiClients from "src/apiClients/apiClients";
// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  padding: 16,
  "&:hover": {
    background: theme.palette.gray,
  },
  "& .MuiListItem-root": {
    padding: 0,
  },
}));

function MainNotification() {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnReadCount] = useState(0);
  const [scheduleId, setScheduleId] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    get_scheduled_notification();
  }, []);

  useEffect(() => {
    if (open && scheduleId && scheduleId.length > 0) {
      updateNotificationReadStatus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const handleClose = (event) => {
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  const get_scheduled_notification = async () => {
    try {
      const response = await apiClients.getScheduleMeetingNotification();
      if (response.data) {
        const filteredNotifications = response?.data?.allMeetings?.filter(
          (item) => item.notifications.message
        );
        const storeIds = response?.data?.allMeetings.map((item) => item.id);

        setScheduleId(storeIds ? storeIds : []);
        setNotifications(filteredNotifications);
        setUnReadCount(response?.data?.unreadCount);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateNotificationReadStatus = async () => {
    try {
      const data = {
        meetingIds: scheduleId,
      };
      const response = await apiClients.updateNotificationReadStatus(data);
      if (response) {
        get_scheduled_notification();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Box>
        <IconButton aria-describedby={id} onClick={handleClick}>
          <Badge badgeContent={unreadCount} color="primary">
            <NotificationsNoneIcon color="action" />
          </Badge>
        </IconButton>
      </Box>
      <Popper
        sx={{
          zIndex: 1300, // Adjust the zIndex value as needed
        }}
        //   id={id} open={open} anchorEl={anchorEl} transition
        placement={"bottom"}
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [5, 20],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps}>
              <Paper>
                <Card sx={{ minWidth: 330 }}>
                  <CardContent>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 330,
                        py: 0,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          maxWidth: 300,
                        },
                        "& .MuiListItemSecondaryAction-root": {
                          top: 22,
                        },
                        "& .MuiDivider-root": {
                          my: 0,
                        },
                        "& .list-container": {
                          pl: 7,
                        },
                      }}
                    >
                      {notifications?.length > 0 ? (
                        notifications?.map((item, index) => (
                          <div key={index}>
                            {/* Add a key prop to the outermost JSX element */}
                            <ListItemWrapper>
                              <ListItem alignItems="center">
                                <ListItemAvatar>
                                  <Avatar sizes="small">
                                    <NotificationsNoneIcon
                                      // stroke={1.5}
                                      fontSize="small"
                                    />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Typography variant="subtitle1">
                                      {item?.notifications?.title}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                              <Grid
                                container
                                direction="column"
                                className="list-container"
                              >
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                  <Typography variant="subtitle2">
                                    {item?.notifications?.message}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ListItemWrapper>
                            <Divider />
                          </div>
                        ))
                      ) : (
                        <Grid item xs={12} sx={{ pb: 2 }}>
                          <Typography variant="subtitle2">
                            No notifications available.
                          </Typography>
                        </Grid>
                      )}
                    </List>
                  </CardContent>
                </Card>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
}

export default MainNotification;
