import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import MainButton from "src/components/Button/MainButton/MainButton";
import GroupIcon from "@mui/icons-material/Group";
import meetingIcon from "src/images/mdi_virtual-meeting.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import apiClients from "src/apiClients/apiClients";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LeftIcon from "src/images/dashboard/chevron-left.svg";
import RightIcon from "src/images/dashboard/chevron-right.svg";

function Prerecording() {
  const [liveRooms, setLiveRooms] = useState();
  const [cardMinHeight, setCardMinHeight] = useState("15rem");
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingRoom, setLoadingRoom] = useState(null);
  useEffect(() => {
    fetchLiveRooms();
  }, []);

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img src={LeftIcon} alt="prevArrow" {...props} />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img src={RightIcon} alt="nextArrow" {...props} />
  );

  // function SampleNextArrow(props) {
  //     const { className, style, onClick } = props;
  //     return (
  //         <div
  //             className={className}
  //             style={{
  //                 ...style,
  //                 display: "block",
  //                 background: "red",
  //                 color: "black",
  //             }}
  //             onClick={onClick}
  //         />
  //     );
  // }
  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    // nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const fetchLiveRooms = async () => {
    try {
      const response = await apiClients.preRecordings();
      if (response.data) {
        let cardheight = "15rem";
        setCardMinHeight(cardheight);
        setLiveRooms(response.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopy = (id) => {
    navigator.clipboard.writeText(`${window.location}/${id}/join`);
    toast.success(
      "The meeting URL has been copied. The link can be used to join the meeting."
    );
  };

  const handlePlay = (url) => {
    window.open(url);
  };
  return (
    <>
      {liveRooms?.length > 0 && (
        <div
          style={{
            paddingTop: "15px",
            marginBottom: "30px",
            background: "rgb(255, 255, 255)",

            width: "95%",
            minHeight: "40vh",
            borderRadius: "12px",
            boxShadow:
              "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "19.6px",
              color: "#0C56AC",
              textAlign: "center",
              marginBottom: "10px",
              marginTop: "40px",
            }}
          >
            ROOM RECORDINGS
          </Typography>
          <Typography
            className="headingTypography"
            sx={{ textAlign: "center", marginBottom: "20px" }}
          >
            Experience stunning video quality with support for HD
          </Typography>
          {liveRooms?.length > 0 ? (
            <Slider {...settings} style={{ marginBottom: "30px" }}>
              {liveRooms?.map((item, index) => (
                <div>
                  <Card
                    sx={{
                      // maxWidth: 345,
                      // maxHeight: "30vh",
                      //
                      minHeight: cardMinHeight,
                      boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.5)",
                      // boxShadow: " 2px 0px 5px rgba(0, 0, 0, 0.2)",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      margin: "10px",
                    }}
                  >
                    <CardContent>
                      <Stack direction="column">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <GroupIcon sx={{ fontSize: "1rem" }} />
                              <span style={{ fontSize: "14px" }}>
                                {item?.recording?.room?.participants}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          mt={3}
                        >
                          <Typography gutterBottom variant="h5" component="div">
                            {item?.recording?.name}
                          </Typography>

                          <img src={meetingIcon} alt="Meeting Icon" />
                        </Stack>
                        <Typography
                          gutterBottom
                          component="div"
                          sx={{
                            color: "#757575",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          Moderator : {item?.recording?.room?.user?.name}
                        </Typography>
                        <Typography
                          gutterBottom
                          sx={{
                            color: "#757575",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                          component="div"
                        >
                          Duration : {item?.recording?.length}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          mt={4}
                        >
                          {/* No previous session created */}
                        </Typography>
                      </Stack>
                    </CardContent>
                    <CardActions>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopy(item?.recording?.room?.friendly_id);
                          }}
                          sx={{ padding: "10px 10px" }}
                        >
                          <ContentCopyIcon />
                        </Button>
                        <MainButton
                          onClick={() => handlePlay(item.url)}
                          style={{ padding: "10px 50px" }}
                        >
                          {loading && loadingRoom === item.friendly_id && (
                            <CircularProgress
                              size={"1.2rem"}
                              sx={{ color: "white" }}
                            />
                          )}
                          <Box ml={loading ? 2 : 0}> Play</Box>
                        </MainButton>
                      </Box>
                    </CardActions>
                  </Card>
                </div>
              ))}
            </Slider>
          ) : (
            <Typography textAlign={"center"} pt={"10%"}>
              No Recordings
            </Typography>
          )}
        </div>
      )}
    </>
  );
}

export default Prerecording;
