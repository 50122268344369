import {
  // Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import MainButton from "src/components/Button/MainButton/MainButton";
import SecondaryButton from "src/components/Button/SecondaryButton/SecondaryButton";
import DeleteButton from "src/components/Button/DeleteButton/DeleteButton";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { setUser } from "src/Redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import apiClients from "src/apiClients/apiClients";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Notification from "src/components/Notification/expiredNotification";
import AccountGif from "src/images/profile/Account.gif";
function AdminProfile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [selectedFile, setSelectedFile] = useState(null);
  const tabsView = useMediaQuery("(max-width:992px)");
  const phonesView = useMediaQuery("(max-width:480px)");
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subscription, setSubscription] = useState();
  const [language, setLanguage] = useState("");
  const [userId, setUserId] = useState(user?.user?.id);
  const [profile, setProfile] = useState(null);
  const [notification, setNotfication] = useState(false);
  const [checkName, setCheckName] = useState(false);
  // const [checkLanguage, setCheckLanguage] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const languages = [
  //   "English",
  //   "Spanish",
  //   "French",
  //   "German",
  //   "Chinese",
  //   "Japanese",
  //   "Russian",
  //   "Portuguese",
  //   "Arabic",
  //   "Hindi",
  //   "Italian",
  //   "Korean",
  //   "Dutch",
  //   "Greek",
  //   "Turkish",
  //   "Swedish",
  //   "Polish",
  //   "Danish",
  //   "Finnish",
  //   "Norwegian",
  //   "Czech",
  //   "Hungarian",
  //   "Hebrew",
  //   "Thai",
  //   "Vietnamese",
  //   "Indonesian",
  //   "Malay",
  //   "Bengali",
  //   "Urdu",
  //   "Punjabi",
  // ];
  useEffect(() => {
    setName(user?.user?.name);
    setEmail(user?.user?.email);
    setLanguage(user?.user?.language);
    setUserId(user?.user?.id);
    setProfile(user?.user?.avatar);
    setSubscription(user?.user?.subscription?.name);
  }, [user]);

  const uploadProfile = async (selectedFile) => {
    try {
      const formData = new FormData();
      formData.append("image", selectedFile);
      setLoading(true);
      const response = await apiClients.uploadProfile(userId, formData);
      if (response) {
        toast.success("avatar has been updated");
        session();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const isFileTypeValid = (file) => {
    const validTypes = ["image/png", "image/jpeg", "image/svg+xml"];
    return validTypes.includes(file.type);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && isFileTypeValid(file)) {
      setProfile(file);
      setSelectedFile(file);
      uploadProfile(file);
    } else {
      setSelectedFile(null);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const session = async () => {
    try {
      const responseData = await apiClients.sessionData();
      if (responseData?.success === true) {
        if (responseData?.data) {
          dispatch(setUser(responseData.data));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkAllField = () => {
    let error;
    if (!name) {
      setCheckName(true);
      error = true;
    }

    // if (!language) {
    //   setCheckLanguage(true);
    //   error = true;
    // }

    if (error) {
      return error;
    } else {
      return false;
    }
  };

  const handleUpdateUser = async () => {
    try {
      const checkRequiredField = checkAllField();
      if (checkRequiredField === false) {
        const data = {
          name,
          email,
          language,
        };
        const response = await apiClients.userUpdate(data, userId);
        if (response.data) {
          if (response.message) {
            toast.success(response.message);
          }
          session();
          // dispatch(setUser(response.data));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteProfile = async () => {
    try {
      const response = await apiClients.deleteProfile(userId);
      if (response.success === true) {
        toast.success("avatar has been updated");
        setSelectedFile(null);
        session();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <>
       <Notification setNotfication={setNotfication} />

      <div>
        <Box>
          <Container maxWidth={"xl"}>
            <Box
              mt={notification ? 5 : 10}
              sx={{
                marginBottom: "20px",
                background: "rgb(255, 255, 255)",
                minHeight: "70vh",
                borderRadius: "12px",
                boxShadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              }}
            >
              <Grid container spacing={4} sx={{ padding: "30px" }}>
                <Grid item xs={12} md={4}>
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      gap: 100,
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                        marginBottom: "1rem",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Box {...getRootProps()}>
                        <input {...getInputProps()} />
                        {selectedFile ? (
                          <Avatar
                            src={URL.createObjectURL(selectedFile)}
                            sx={{
                              width: 120,
                              height: 120,
                              margin: "0 auto",
                              border: "4px solid #fff",
                              cursor: "pointer",
                              // backgroundColor: "#6D207B",
                            }}
                          />
                        ) : (
                          <Avatar
                            src={`${process.env.REACT_APP_OVERRIDE_HOST}/api/images/${profile}`}
                            sx={{
                              width: 120,
                              height: 120,
                              margin: "0 auto",
                              border: "4px solid #fff",
                              cursor: "pointer",
                              backgroundColor: "#6D207B",
                            }}
                          />
                        )}
                      </Box>
                      <Box {...getRootProps()}>
                        <input {...getInputProps()} />
                        <MainButton>
                          {loading ? (
                            <CircularProgress
                              size={"1.2rem"}
                              sx={{ color: "white", mr: 1 }}
                            />
                          ) : (
                            <FileUploadOutlinedIcon sx={{ mr: 1 }} />
                          )}
                          Upload photo
                        </MainButton>
                      </Box>
                      {/* onClick={uploadProfile} */}
                      <DeleteButton onClick={deleteProfile}>
                        <HighlightOffOutlinedIcon sx={{ mr: 1 }} />
                        Remove photo
                      </DeleteButton>
                    </Box>
                    {!tabsView && !phonesView && (
                      <Box>
                        <Divider orientation="vertical" />
                      </Box>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography
                      // style={{ fontSize: "2rem", fontWeight: 400 }}
                      variant="h3"
                      gutterBottom
                    >
                      Update Account info
                    </Typography>
                    <Box display={"flex"} flexDirection={"column"} gap={3}>
                      <Box>
                        <Typography gutterBottom>Name *</Typography>
                        <TextField
                          type="text"
                          placeholder="Name"
                          name="name"
                          fullWidth
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setCheckName(false);
                          }}
                          error={checkName}
                          helperText={checkName ? "Name is required" : ""}
                        />
                      </Box>
                      <Box>
                        <Typography gutterBottom>Email *</Typography>
                        <TextField
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          fullWidth
                          disabled
                        />
                      </Box>
                      {/* <Box>
                        <Typography gutterBottom>Language *</Typography>

                        <Autocomplete
                          options={languages}
                          value={language}
                          onChange={(e, value) => {
                            setLanguage(value);
                            setCheckLanguage(false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Language"
                              error={checkLanguage}
                              helperText={
                                checkLanguage ? "Language is required" : ""
                              }
                            />
                          )}
                          // style={{ width: 300 }}
                        />
                      </Box> */}
                      {/* <Box>
                        <Typography gutterBottom>Subscription *</Typography>
                        <TextField
                          type="text"
                          placeholder="Subscription"
                          name="language"
                          value={subscription}
                          // onChange={(e) => setLanguage(e.target.value)}
                          // readonly
                          disabled
                          fullWidth
                        />
                        
                      </Box> */}
      <Box>
      <Typography gutterBottom>Subscription *</Typography>
      <TextField
        type="text"
        placeholder="Subscription"
        name="subscription"
        value={subscription}
        disabled
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
           <Button onClick={()=>navigate("/settings/mysubscription")} sx={{ border: "1px solid black",}}>
            Upgrade
           </Button>
            </InputAdornment>
          ),
        }}
      />
    </Box>
                      <Box
                        display={{ xs: "flex", sm: "flex" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        justifyContent={{ xs: "center", sm: "flex-end" }}
                        gap={1}
                      >
                        <SecondaryButton onClick={handleCancel}>
                          Cancel
                        </SecondaryButton>
                        <MainButton onClick={handleUpdateUser}>
                          Update
                        </MainButton>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"100%"}
                    flexDirection={"column"}
                  >
                    {/* <Box>
                        <DeleteButton>
                          <DeleteOutlinedIcon sx={{ mr: 1 }} />
                          Delete account
                        </DeleteButton>
                      </Box> */}
                    <Box>
                      <img src={AccountGif} alt="profileicon" />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </div>
    </>
  );
}

export default AdminProfile;
