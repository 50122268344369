import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MainButton from "../Button/MainButton/MainButton";
import SecondaryButton from "../Button/SecondaryButton/SecondaryButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});

export default function NewRoom({ open, handleClose, handleCreateRoom }) {
  const [roomName, setRoomName] = useState("");
  const [checkName, setCheckName] = useState(false);
  const handleClosebox = () => {
    setCheckName(false);
    setRoomName("");
    handleClose();
  };

  const CreateRoom = () => {
    if (roomName) {
      setCheckName(false);
      handleCreateRoom(roomName);
      setRoomName("");
      handleClose();
    } else {
      setCheckName(true);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosebox}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textAlign: "center", backgroundColor: "#F5F7FB" }}>
          {"Create a new room"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ mt: 6 }}>
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <Typography gutterBottom>Room Name *</Typography>
                  <TextField
                    type="text"
                    placeholder="Enter a room name..."
                    name="name"
                    fullWidth
                    value={roomName}
                    onChange={(e) => {
                      setCheckName(false);
                      setRoomName(e.target.value);
                    }}
               
                    error={checkName}
                    helperText={checkName ? "Name is required" : ""}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                
                >
                  <Box
                    display={{ xs: "flex", sm: "flex" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    justifyContent={{ xs: "center", sm: "flex-end" }}
                    gap={1}
                  >
                    <SecondaryButton onClick={handleClosebox}>
                      Cancel
                    </SecondaryButton>
                    <MainButton onClick={CreateRoom}>Create Room</MainButton>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
