import React, { useState, useEffect } from "react";
import "../../dashboard/editUser/EditUser.css";
import { toast } from "react-toastify";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  TextField,
  Typography,
  Switch,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import apiClients from "src/apiClients/apiClients";
import MainButton from "src/components/Button/MainButton/MainButton";
import SecondaryButton from "src/components/Button/SecondaryButton/SecondaryButton";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});

function EditSubscription({ open, handleclose, data, fetchData }) {
  // const [formData, setFormData] = useState({ data });
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    price: null,
    participants: null,
    duration: null,
    storage: null,
    Validity: null,
    recording: "false",
    chat: "false",
    sharedNotes: "false",
    breakout: "false",
    screenshare: "false",
    multiuserwhiteboard: "false",
    period:"month",
    sharedRoomAccess:"false"
  });
  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let parsedValue;

    if (
      ["price", "participants", "duration", "Validity", "storage"].includes(
        name
      )
    ) {
      parsedValue = parseInt(value, 10);
    } else {
      parsedValue = value;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: parsedValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleToggleChange = (property) => {
    setFormData((prevData) => ({
      ...prevData,
      [property]: prevData[property] === "true" ? "false" : "true",
    }));
  };

  const handleClosebox = () => {
    handleclose();
  };

  const handleSubmit = async () => {
    try {
      const newErrors = {};
      const requiredFields = [
        "name",
        "price",
        "participants",
        "duration",
        "Validity",
        "storage",
      ];
      requiredFields.forEach((field) => {
        const value = formData[field];
        if (
          value === undefined ||
          value === null ||
          (typeof value === "string" && value.trim() === "") ||
          (typeof value === "number" && isNaN(value))
        ) {
          newErrors[field] = "This field is required.";
        }
      });

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setErrors({});
        const response = await apiClients.updatePlan(formData);
        if (response.success === true) {
          toast.success(response.message);
          fetchData();
          handleClosebox();
        }
        console.log(formData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Handle change for Select inputs
  const handleChangevalue = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Reset validity if period changes
    if (name === "period") {
      setFormData((prev) => ({ ...prev, Validity: "" }));
    }
  };

  // Determine number of validity options based on selected period
  const getValidityOptions = (period) => {
    return period === "month"
      ? [...Array(12).keys()].map((number) => number + 1)
      : period === "day"
      ? [...Array(30).keys()].map((number) => number + 1)
      : [];
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosebox}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textAlign: "center", backgroundColor: "#F5F7FB" }}>
          Edit Subscription Plan
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <Typography gutterBottom>Plan</Typography>
                  <TextField
                    type="text"
                    name="name"
                    value={formData?.name}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Price</Typography>
                  <TextField
                    type="Number"
                    name="price"
                    value={formData?.price}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.price}
                    helperText={errors.price}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Participants</Typography>
                  <TextField
                    type="Number"
                    name="participants"
                    value={formData?.participants}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.participants}
                    helperText={errors.participants}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Period</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="period-select-label"
                      name="period"
                      value={formData?.period}
                      onChange={handleChangevalue}
                    >
                      <MenuItem value="day">Day</MenuItem>
                      <MenuItem value="month">Month</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Validity</Typography>
                  <FormControl fullWidth error={!!errors.Validity}>
                    <Select
                      labelId="validity-select-label"
                      name="Validity"
                      value={formData?.Validity}
                      onChange={handleChange}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 200, // Set the maximum height of the dropdown menu
                            overflowY: "auto", // Ensure scroll if content exceeds height
                          },
                        },
                      }}
                    >
                      {getValidityOptions(formData?.period).map((number) => (
                        <MenuItem
                          key={number}
                          value={number}
                          sx={{
                            padding: "8px 16px", // Adjust padding if needed
                            fontSize: "0.875rem", // Adjust font size if needed
                          }}
                        >
                          {number}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.Validity}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Duration</Typography>
                  <TextField
                    type="Number"
                    name="duration"
                    value={formData?.duration}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.duration}
                    helperText={errors.duration}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Storage</Typography>
                  <TextField
                    type="number"
                    name="storage"
                    value={formData?.storage}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.storage}
                    helperText={errors.storage}
                  />
                </Grid>
                <Grid container item xs={12} sm={12} alignItems="center">
                  <Typography gutterBottom>MultiUser Whiteboard</Typography>
                  <Box sx={{ marginLeft: "auto" }}>
                    <Switch
                      name="multiuserwhiteboard"
                      checked={formData?.multiuserwhiteboard === "true"}
                      onChange={() => handleToggleChange("multiuserwhiteboard")}
                    />
                  </Box>
                </Grid>
                <Grid container item xs={12} sm={12} alignItems="center">
                  <Typography gutterBottom>Recording</Typography>
                  <Box sx={{ marginLeft: "auto" }}>
                    <Switch
                      name="recording"
                      checked={formData?.recording === "true"}
                      onChange={() => handleToggleChange("recording")}
                    />
                  </Box>
                </Grid>
                <Grid container item xs={12} sm={12} alignItems="center">
                  <Typography gutterBottom>Screen Share</Typography>
                  <Box sx={{ marginLeft: "auto" }}>
                    <Switch
                      name="screenshare"
                      checked={formData?.screenshare === "true"}
                      onChange={() => handleToggleChange("screenshare")}
                    />
                  </Box>
                </Grid>
                <Grid container item xs={12} sm={12} alignItems="center">
                  <Typography gutterBottom>Share Access</Typography>
                  <Box sx={{ marginLeft: "auto" }}>
                    <Switch
                      name="screenshare"
                      checked={formData?.sharedRoomAccess === "true"}
                      onChange={() => handleToggleChange("sharedRoomAccess")}
                    />
                  </Box>
                </Grid>
                <Grid container item xs={12} sm={12} alignItems="center">
                  <Typography gutterBottom>Chat</Typography>
                  <Box sx={{ marginLeft: "auto" }}>
                    <Switch
                      name="chat"
                      checked={formData?.chat === "true"}
                      onChange={() => handleToggleChange("chat")}
                    />
                  </Box>
                </Grid>
                <Grid container item xs={12} sm={12} alignItems="center">
                  <Typography gutterBottom>Shared Notes</Typography>
                  <Box sx={{ marginLeft: "auto" }}>
                    <Switch
                      name="sharedNotes"
                      checked={formData?.sharedNotes === "true"}
                      onChange={() => handleToggleChange("sharedNotes")}
                    />
                  </Box>
                </Grid>
                <Grid container item xs={12} sm={12} alignItems="center">
                  <Typography gutterBottom>Breakout</Typography>
                  <Box sx={{ marginLeft: "auto" }}>
                    <Switch
                      name="breakout"
                      checked={formData?.breakout === "true"}
                      onChange={() => handleToggleChange("breakout")}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mb: 2,
                    gap: 1,
                  }}
                >
                  <SecondaryButton onClick={handleClosebox}>
                    Cancel
                  </SecondaryButton>
                  <MainButton onClick={handleSubmit}>Update</MainButton>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default EditSubscription;
