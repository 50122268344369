import React from 'react'

const ServerRecordingIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 11.89V12.78C22 16.34 21.11 17.22 17.56 17.22H6.44C2.89 17.22 2 16.33 2 12.78V6.44C2 2.89 2.89 2 6.44 2H8" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 17.2207V22.0007" stroke="##6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 13H22" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.5 22H16.5" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.8598 9.36977H13.0998C11.7198 9.36977 11.2598 8.44977 11.2598 7.52977V4.00977C11.2598 2.90977 12.1598 2.00977 13.2598 2.00977H17.8598C18.8798 2.00977 19.6998 2.82977 19.6998 3.84977V7.52977C19.6998 8.54977 18.8798 9.36977 17.8598 9.36977Z" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.9092 7.92046L19.6992 7.07046V4.31046L20.9092 3.46046C21.5092 3.05046 21.9992 3.30046 21.9992 4.03046V7.36046C21.9992 8.09046 21.5092 8.34046 20.9092 7.92046Z" stroke="#6D207B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default ServerRecordingIcon
